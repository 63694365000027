import request from '@/utils/request'

// 获取通道产品list
export function list(data) {
  return request({
    url: '/api/news-finance/product/h5list',
    method: 'get',
    params: data,
  })
}

// 获取通道产品详情
export async function getProductInfo(id) {
  return await request({
    url: '/api/news-finance/product/' + id,
    method: 'get',
  })
}


//通道首页获取<=2条产品信息

export async function indexList() {
  return await request({
    url: '/api/news-finance/product/indexList',
    method: 'get',
  })
}
