<template>
  <div>
    <el-container>
      <el-card class="box-card" style="width: 100%;max-height:100%;background-color: white" v-loading="loading">
        <div slot="header" class="clearfix">
          <span style="font-weight: 700;color: black;">投资人权益须知</span>
          <el-button style="float: left; color: black" type="text" @click="backTo" icon="el-icon-arrow-left"></el-button>
        </div>
        <div style="color: black;">
          <div>
            <a style="font-size: 18px; font-weight: bold">
              南京凡字码网络技术有限公司
            </a>
          </div>
          <div style="margin-top: 10px;">
            <a>
              投资人权益须知
            </a>
          </div>
          <div style="text-align: left">
            <el-row class="text">
              <div style="font-size: 20px;font-weight: bold">
                重要提示:
              </div>
            </el-row>
            <el-row class="text">
              <div>
                尊敬的基金投资人：
              </div>
            </el-row>
            <el-row class="text">
              基金（含资管计划及本公司代销的其他理财产品，下同）投资在获取收益的同时存在投资风险。为了保护您的合法权益，请在投资基金前认真阅读以下内容：
            </el-row>
            <el-row class="text">
              <div style="font-size: 20px;">
                一、基金的基本知识
              </div>
              <div style="font-size: 15px;">
                （一）什么是基金
              </div>
              <div>
                证券投资基金（简称基金）是指通过发售基金份额，将众多投资者的资金集中起来，形成独立财产，由基金托管人托管，基金管理人管理，以投资组合的方法进行证券投资的一种利益共享、风险共担的集合投资方式。
              </div>

              <div>
                （二）基金的分类
              </div>
              <div>
                依据运作方式的不同，可分为封闭式基金与开放式基金。
                封闭式基金是指基金份额在基金合同期限内固定不变，基金份额可以在依法设立的证券交易所交易，但基金份额持有人不得申请赎回的一种基金运作方式。

                开放式基金是指基金份额不固定，基金份额可以在基金合同约定的时间和场所进行申购和赎回的一种基金运作方式。

                依据投资对象的不同可分为股票基金、债券基金、货币市场基金、混合基金。
                根据《公开募集证券投资基金运作管理办法》对基金类别的分类标准,80%以上的基金资产投资于股票的为股票基金；80%以上的基金资产投资于债券的为债券基金；仅投资于货币市场工具的为货币市场基金；80%以上的基金资产投资于其他基金份额的，为基金中基金；投资于股票、债券、货币市场工具或其他基金份额，但股票投资、债券投资、基金投资的比例不符合股票基金、债券基金、基金中基金规定的为混合基金。这些基金类别按收益和风险由高到低的排列顺序一般为：股票基金、混合基金、基金中基金/债券基金、货币市场基金，即股票基金的风险和收益最高，货币市场基金的风险和收益相对较低。

                依据募集方式的不同可分为公开募集基金和私募基金。
                公募基金可以公开推介，向不特定投资者募集，没有投资者数量和投资门槛限制。私募基金只能向合格投资者非公开募集，投资者数量不得突破200人且有最低投资金额要求。

                依据管理人类型不同可分为投资基金和资管计划。
                资管计划一般由证券公司、期货公司，或其子公司发行管理，包括参照公募基金管理的大集合产品和私募性质的私募资管计划。

                特殊类型基金
                系列基金
                又被称为伞型基金，是指多个基金共用一个基金合同,子基金独立运作,子基金之间可以进行相互转换的一种基金结构形式。

                避险策略基金
                是指通过一定的避险投资策略进行运作，同时引入相关保障机制，以在避险策略周期到期时，力求避免基金份额持有人投资本金出现亏损的公开募集证券投资基金。

                交易型开放式指数基金(ETF)与 ETF 联接基金
                交易型开放式指数基金,通常又被称为交易所交易基金(Exchange Traded Funds,简称“ETF”),是一种在交易所上市交易的、基金份额可变的一种开放式基金。它结合了开放式基金和封闭式基金的运作特点，其份额可以在二级市场买卖，也可以申购、赎回。但是，由于它的申购是用一篮子成分券换取基金份额，赎回也是换回一篮子成分券而非现金。为方便未参与二级市场交易的投资者，就诞生了“ETF 联接基金”，这种基金将 90%以上的资产投资于目标 ETF，采用开放式运作方式并在场外申购或赎回。

                上市开放式基金(Listed Open-ended Funds,简称“LOF”)
                是一种既可以在场外市场进行基金份额申购赎回，又可以在交易所（场内市场）进行基金份额交易、申购或赎回的开放式基金。

                QDII基金
                QDII 是 Qualified Domestic InstitutionalInvestors 的首字母缩写。它是指在一国境内设立，经该国有关部门批准从事境外证券市场的股票、债券等有价证券投资的基金。它为国内投资者参与国际市场投资提供了便利。

                分级基金
                是指通过事先约定基金的风险收益分配，将基础份额分为预期风险收益不同的子份额，并可将其中部分或全部份额上市交易的结构化证券投资基金。
              </div>

              <div>
                （三）基金评级
              </div>
              <div>
                基金评级是依据一定标准对基金产品进行分析从而做出相对优劣评价。投资人在投资基金时，可以适当参考基金评级结果，但切不可把基金评级作为选择基金的唯一依据。此外，基金评级是对基金管理人过往的业绩表现做出评价，并不代表基金未来长期业绩的表现。

                本公司将根据销售适用性原则，对基金管理人进行审慎调查，并对基金产品进行风险评价。
              </div>

              <div>
                （四）基金费用
              </div>
              <div>
                基金费用一般包括两大类：一类是在基金销售过程中发生的由基金投资人自己承担的费用，主要包括认购费、申购费、赎回费和基金转换费。这些费用一般直接在投资人认购、申购、赎回或转换时收取。其中认（申）购费可在投资人购买基金时收取，即前端认（申）购费；也可在投资人赎回基金时收取，即后端认（申）购费，其费率按照产品类型的不同有所区别。后端认（申）购费率和赎回费率一般会按持有期递减。另一类是在基金管理过程中发生的费用，主要包括基金管理费、基金托管费、基金外包服务费等，这些费用由基金资产承担。对于不收取申购、赎回费的货币市场基金和部分其他基金，还可按相关规定从基金资产中计提一定的销售服务费，专门用于本基金的销售和对基金持有人的服务。
              </div>
            </el-row>
            <el-row class="text">
              <div style="font-size: 20px;">
                二、基金投资风险提示
              </div>
              <div>
                证券投资基金是一种长期投资工具，其主要功能是分散投资，降低投资单一证券所带来的个别风险。基金不同于银行储蓄和债券等能够提供固定收益预期的金融工具，投资人购买基金，既可能按其持有份额分享基金投资所产生的收益，也可能承担基金投资所带来的损失。

                基金在投资运作过程中可能面临各种风险，既包括市场风险，也包括基金自身的管理风险、技术风险和合规风险等,QDII基金还会面临汇率波动风险。巨额赎回风险是开放式基金所特有的一种流动性风险，即当单个交易日基金的净赎回申请超过基金总份额的百分之十时，投资人将可能无法及时赎回持有的全部基金份额。

                基金投资人应当充分了解基金定期定额投资和零存整取等储蓄方式的区别。定期定额投资是引导投资人进行长期投资、平均投资成本的一种简单易行的投资方式，但并不能规避基金投资所固有的风险，不能保证投资人获得收益，也不是替代储蓄的等效理财方式。

                基金管理人承诺以诚实守信、勤勉尽责的原则管理和运用基金资产，但不保证旗下基金一定盈利，也不保证最低收益。旗下基金的过往业绩及其净值高低并不预示其未来业绩表现。基金管理人提醒投资人基金投资的“买者自负”原则，在做出投资决策后，基金运营状况与基金净值变化引致的投资风险，由投资人自行负担。

                本公司将对归类为普通投资者的基金投资人的风险承受能力进行调查和评价，并根据基金投资人的风险承受能力推荐相应的基金品种，但我公司所做的推荐仅供投资人参考，投资人应根据自身风险承受能力选择基金产品并自行承担投资基金的风险。根据有关适当性监管规定，除风险承受能力最低的投资人以外，本公司不禁止投资人购买超过其风险承受水平的公募基金产品（包括参照公募管理的大集合产品），本公司会对此类购买行为予以风险提示，投资者坚持购买则表明投资者自行承担投资风险。本公司提醒投资人基金投资的“买者自负”原则，在做出投资决策后，基金运营状况与基金净值变化引致的投资风险，由投资人自行负担。

                本公司仅向合格投资者销售私募理财产品，包括私募投资基金和私募资管计划等。本公司禁止投资人购买超过其风险承受能力的私募产品。投资人应保证其提交的各项证明材料的真实性，这些材料包括但不限于风险测评资料、投资经历证明、资产或收入证明等。本公司提醒投资人，私募理财产品相对公募产品，风险和收益具有更大的不确定性。
              </div>
            </el-row>
            <el-row class="text">
              <div style="font-size: 20px;">
                三、本公司基本信息
              </div>
              <el-row>
                公司名称: 南京凡字码网络技术有限公司
              </el-row>
              <el-row>
                地址: 南京市秦淮区蓝旗街21幢前平房1-442A室
              </el-row>
              <el-row>
                法定代表人: 李海方
              </el-row>
            </el-row>
          </div>
        </div>
      </el-card>
    </el-container>
  </div>
</template>
<script>
export default {
  name: 'BasNotice',
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    backTo() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
.text {
  margin-top: 10px;
}
</style>
