import request from '@/utils/request'

// 获取首页图片
export async function indexImg(deptId) {
  return await request({
    url: '/api/news-finance//bas/set/indexImg',
    method: 'get',
    params: {
      deptId: deptId
    }
  })
}
