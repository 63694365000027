<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">实名认证</span>
    </header>

    <div class="body">
      <div class="text1">
        请上传账号实际使用人身份信息，实名认证成功后将与账号绑定，不支持更改
      </div>
      <div class="img1">
        <el-upload
          :auto-upload="false"
          :action="uploadURL"
          list-type="picture-card"
          :show-file-list="false"
          accept=".JPG, .png, .jpeg"
          multiple="multiple"
          :on-change="upCardImgChange"
        >
          <img v-if="personalForm.merIdenImgFront" :src="personalForm.merIdenImgFront" class="avatar"
               style="width:100%;height:100%">
          <img v-else src="@/assets/sfzzm.png" style="width:100%;height:100%">
        </el-upload>
      </div>

      <div class="img1">
        <el-upload
          :auto-upload="false"
          :action="uploadURL"
          list-type="picture-card"
          :show-file-list="false"
          accept=".JPG, .png, .jpeg"
          multiple="multiple"
          :on-change="downCardImgChange"
        >
          <img v-if="personalForm.merIdenImgBack" :src="personalForm.merIdenImgBack" class="avatar"
               style="width:100%;height:100%">
          <img v-else src="@/assets/sfzfm.png" style="width:100%;height:100%">
        </el-upload>
      </div>

      <div class="checkbox-group">
        <el-checkbox class="body-checkbox" v-model="agreedToTerms">
          已阅读并同意
        </el-checkbox>
        <div class="checkbox-text" @click="handleClick(0)">《人脸验证协议》</div>
        <div class="checkbox-text" @click="handleClick(2)">《实名认证服务协议》</div>
      </div>

      <el-button class="but-sy" @click="handleAuthen">
        <div class="but-text">同意协议并认证</div>
      </el-button>
    </div>
  </div>
</template>

<script>

import {idCardOcr} from "@/api/est/psnAuth";
import {upload} from "@/api/upload/upload";
import {userIsAuth, upIdCardAuthen, getMyIdCardAuthenInfoV2} from '@/api/authen/authen'

export default {
  name: 'UserAuthen',
  data() {
    return {
      userId: this.$store.state.userId,
      sfzzm: '@/assets/sfzzm.png',
      sfzfm: '@/assets/sfzfm.png',
      agreedToTerms: false,
      uploadURL: '',
      imgList: [],
      personalForm: {
        merIdenImgFront: '',
        merIdenImgBack: ''
      },
      form: {
        amount: '',
        name: '',
        idNumber: '',
        bankNumber: '',
        bankName: '',
        bankAddress:'',
        phone: '',
        address: '',
        birthday: '',
        gender: '',
        nation: '',
        issuedBy: '',
        validityPeriod: '',
      },
    };
  },
  created() {
    this.getInfo()
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    getInfo() {
      getMyIdCardAuthenInfoV2({userid: this.userId, authentype: 0}).then(res => {
        if (res.code == 200) {
          if (res.dataInfo != null) {
            let dataInfo = res.dataInfo
            this.personalForm.merIdenImgFront = dataInfo.idcardforwardimg
            this.personalForm.merIdenImgBack = dataInfo.idcardreverseimg
            this.agreedToTerms = true
          }
        }
      })
    },
    handleAuthen () {
      if (!this.agreedToTerms) {
        return this.$message.error('请先阅读并同意协议')
      }
      let params = {
        userid: this.userId,
        authentype: 0,
        username: this.form.name,
        usernum: this.form.idNumber,
        idcardforwardimg: this.imgList[0],
        idcardreverseimg: this.imgList[1],
        address: this.form.address,
        gender: this.form.gender,
        nation: this.form.nation,
        birthday: this.form.birthday
      }
      upIdCardAuthen(params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.error('认证失败')
        }
      })
    },
    upCardImgChange(item) {
      this.personalForm.merIdenImgFront = URL.createObjectURL(item.raw)
      const data = new FormData()
      data.append('file', item.raw)
      data.append('type', 1)
      idCardOcr(data).then(res => {
        if (res.dataInfo.code == 0) {
          const result = res.dataInfo.data
          this.form.idNumber = result.idNo
          this.form.name = result.name
          this.form.address = result.address
          this.form.birthday = result.birthDay
          this.form.gender = result.gender
          this.form.nation = result.nation
          this.uploadUpImg(item)
        } else {
          this.$message.error(res.dataInfo.message)
        }
      })
    },
    downCardImgChange(item) {
      this.personalForm.merIdenImgBack = URL.createObjectURL(item.raw)
      const data = new FormData()
      data.append('file', item.raw)
      data.append('type', 2)
      idCardOcr(data).then(res => {
        if (res.dataInfo.code == 0) {
          const result = res.dataInfo.data
          this.form.validityPeriod = result.validityPeriod
          this.form.issuedBy = result.issuedBy
          this.uploadBackImg(item)
        } else {
          this.$message.error(res.dataInfo.message)
        }
      })
    },
    uploadUpImg(item) {
      console.log(item)
      let formData = new FormData()
      formData.append('file', item.raw)
      upload(formData).then(res => {
        this.imgList[0] = res.url
      })
    },
    uploadBackImg(item) {
      let formData = new FormData()
      formData.append('file', item.raw)
      upload(formData).then(res => {
        this.imgList[1] = res.url
      })
    },
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}

.text1 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  text-align: center;
}

.img1 {
  margin-top: 20px;
}

.checkbox-group {
  margin-top: 10px;
  display: flex;
  margin-left: 20px;
}

.body-checkbox {
  font-size: 13px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}

.checkbox-text {
  font-size: 13px;
  font-weight: 400;
  color: rgba(5, 37, 74, 1);
}

::v-deep .el-checkbox__inner {
  border-radius: 20px;
}

.but-sy {
  margin-top: 20px;
  width: 300px;
  height: 50px;
  border-radius: 37px;
  background: linear-gradient(135deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);

}

.but-text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

::v-deep .el-upload--picture-card {
  width: auto;
}
</style>
