import CryptoJS from 'crypto-js'

let encryptKey = CryptoJS.enc.Utf8.parse('CJAVAPmXcuAksWmF'),
  encryptIV = CryptoJS.enc.Utf8.parse('V33CQ1428SI8ZNMT')

//加密值，转成Base64确保不包含特殊字符
export function encryptByAES (value) {
  const text =  CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(value)), encryptKey, {
    iv: encryptIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text))
}

//解密值
export function decryptByAES (text) {
  const value= CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(text))
  let decrypted = CryptoJS.AES.decrypt(value, encryptKey, {
    iv: encryptIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
}
