<template>
  <div class="app">
    <div class="header">
      <div class="logo">
        <img src="@/assets/hmlogo.png" alt="Logo" />
      </div>
      <div class="text">
        <div class="text1">毫米平台</div>
        <div class="text2">每天赚上一毫米</div>
      </div>
    </div>

    <div class="body-card">
      <el-card shadow="never" style="border-color: white">
        <div class="body">
          <el-form :model="loginForm" class="login-form">
            <el-form-item>
              <el-input
                v-model="loginForm.phone"
                placeholder="请输入手机号码"
                prefix-icon="el-icon-mobile-phone"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="verification-code-container">
                <el-input v-model="loginForm.code" placeholder="短信验证码" prefix-icon="el-icon-lock"/>
                <el-button :disabled="countdown > 0" @click="sendCode">{{ countdown > 0 ? `${countdown}秒后重新发送` : '发送验证码' }}</el-button>
              </div>
            </el-form-item>
            <el-button class="login-bt" @click="login">登录/开户</el-button>
          </el-form>
        </div>
        <div class="checkbox-group">
          <el-checkbox class="body-checkbox" v-model="agreedToTerms">
            我已阅读并同意
          </el-checkbox>
          <div class="checkbox-text" @click="handleClick(0)">《用户服务协议》</div>
          <div class="checkbox-text" @click="handleClick(2)">《投资人权益须知》</div>
        </div>

        <div style="margin-top: 20%">
          <el-divider>更多登录方式</el-divider>
        </div>

        <div class="more-login">
          <img src="@/assets/bas/wx.png" @click="handleWxLogin">
        </div>

      </el-card>
    </div>
  </div>
</template>

<script>
import {loginByCode, sendMessageCode} from "@/api/login/login";
import {setToken} from "@/utils/auth";

export default {
  name: 'BasNewLogin',
  data() {
    return {
      agreedToTerms: false,
      routePushType: this.$route.params.type,
      loginForm: {
        devicetype: 2,
        phone: '',
        code: ''
      },
      countdown: 0,
      timer: null
    };
  },
  created() {
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    sendCode() {
      const data = {
        telPhone: this.loginForm.phone,
        devicetype: this.loginForm.devicetype
      }
      sendMessageCode(data).then(res => {
        if (res != null && res.code == 200) {
          this.$message.success('发送成功')
        } else {
          this.$message.error('发送失败')
        }
      })
      if (this.countdown === 0) {
        this.countdown = 60
        this.timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--
          } else {
            clearInterval(this.timer)
          }
        }, 1000)
      }
    },
    login() {
      if (!this.agreedToTerms) {
        this.$message.error('请阅读并同意隐私政策和用户服务协议');
        return;
      }
      const data = {
        mobile: this.loginForm.phone,
        checkCode: this.loginForm.code,
        devicetype: this.loginForm.devicetype
      }
      loginByCode(data).then(res => {
        const dataInfo = res.dataInfo
        if (dataInfo != null) {
          const authToken = dataInfo.token
          const userId = dataInfo.userId
          if (authToken) {
            setToken(authToken)
            this.$store.commit('setToken', authToken)
            this.$store.commit('setUserId', userId)
            this.$store.commit('setPhone', this.loginForm.phone)
            this.$router.go(-1)
          }
        }
      })
    },
    handleClick(type) {
      if (type == 0) {
        this.$router.push('/serviceAgreement')
      } else if (type == 1) {

      } else if (type == 2) {
        this.$router.push('/notice')
      }
    },
    handleWxLogin() {
      this.$message.error('暂未开放');
    },
  }
};
</script>

<style scoped>
.app {
  //padding: 20px;
}

.header {
  display: flex;
  //margin-top: 10%;
  background-image: linear-gradient(41.11deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
  height: 300px;
}

.text {
  flex-direction: column;
  margin-left: 10px;
  margin-top: 100px;
}

.text1 {
  font-size: 25px;
  font-weight: bolder;
  color: white;
}

.text2 {
  font-size: 12px;
  color: white;
}

.logo img {
  width: 60px;
  margin-left: 40px;
  margin-top: 100px;
}

.body-card {
  position: fixed;
  width: 80%;
  margin-left: 10%;
  top: 250px;
}

.body {
  //margin-top: 30%;
}

.login-bt {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(41.11deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
  color: white;
  margin-top: 30px;
}

.verification-code-container {
  display: flex;
  justify-content: space-between;
}
.verification-code-container .el-input {
  flex: 1;
  margin-right: 10px;
}

.body-checkbox {
  margin-top: 20px;
}

.more-login {
  text-align: center;
}

.more-login img {
  width: 40px;
}

.checkbox-group {
  width: 300px; /* 设置容器宽度 */
  //display: flex;
}

.checkbox-text {
  font-size: 14px;
  color: rgba(234, 182, 125, 1)
}


::v-deep .el-checkbox__inner {
  border-radius: 20px;
}

</style>
