import request from '@/utils/request'

// 上传图片
export function upload (data) {
  return request({
    url: '/api/news-upload/api/img/upload',
    method: 'post',
    data: data,
  })
}
