<template>
  <div
    class="app"
    v-loading="loading"
    element-loading-text="等待合同加载"
    element-loading-spinner="el-icon-loading"
  >
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">购买申请</span>
    </header>

    <div class="body">
      <div class="card1">
        <div class="card1-text1">华润信托·腾飞5号集合资金信托计划</div>
        <div class="card1-text2">￥{{dataInfo.money}}</div>
      </div>
      <div class="card2">
        <div class="card2-text1">收款账户</div>
        <el-row style="margin-top: 20px;">
          <el-col :span="6">
            <div class="card2-text2">户名</div>
          </el-col>
          <el-col :span="18">
            <div class="card2-text3">{{data.bankName}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="6">
            <div class="card2-text2">银行账号</div>
          </el-col>
          <el-col :span="18">
            <div class="card2-text3">{{data.bankCard}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="6">
            <div class="card2-text2">开户行</div>
          </el-col>
          <el-col :span="18">
            <div class="card2-text3">{{data.bankAddress}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <div style="text-align: left">
          <img class="card2-img" src="@/assets/gmsq.png">
          <span class="card2-span">为避免交易失败，请确保使用交易时选择的银行卡转账，且转账金额与交易金额需每笔对应</span>
        </div>
        <div style="text-align: left">
          <img class="card2-img" src="@/assets/gmsq.png">
          <span class="card2-span">请勿使用其他行或支付宝、微信等第三方支付工具间接转账</span>
        </div>
      </div>
      <div class="card3">
        <div class="card3-text1">认购信息</div>
        <el-row style="margin-top: 20px;">
          <el-col :span="6">
            <div class="card3-text2">产品名称</div>
          </el-col>
          <el-col :span="18">
            <div class="card3-text3">{{data.cpmc}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="6">
            <div class="card3-text2">认购金额</div>
          </el-col>
          <el-col :span="18">
            <div class="card3-text3">¥{{dataInfo.money}}元</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="6">
            <div class="card3-text2">付款账户</div>
          </el-col>
          <el-col :span="18">
            <div class="card3-text3">{{dataInfo.bankName}} | {{ handleBankNum() }}</div>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-button class="but-next" @click="handleNext">
      <div class="but-text">确认并签约</div>
    </el-button>

    <el-drawer
      title="合同及协议"
      :visible.sync="drawer"
      direction="btt"
      :show-close="false"
      :before-close="handleClose">
      <div style="padding: 10px;">
        <div class="drawer-div" v-for="(item, index) in signDataInfo" :key="index" @click="handleSign(item)">
          <el-row style="margin-bottom: 20px;">
            <el-col :span="22">
              <div class="drawer-text1">{{item.fileName}}</div>
            </el-col>
            <el-col :span="2">
              <i class="el-icon-arrow-right"></i>
            </el-col>
          </el-row>
        </div>
        <el-button class="but-next" @click="handleCheckSign">
          <div class="but-text">签署合同并继续</div>
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {getProductInfo} from "@/api/product/product";
import {checkUserSign, getWaitSignContractList, startSign} from "@/api/signContract/signContract";
import {getMyIdCardAuthenInfoV2} from "@/api/authen/authen";

export default {
  name: 'BuyStep2',
  props: {
    formData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      deptId: this.$store.state.deptId,
      userId: this.$store.state.userId,
      productId: this.$store.state.productId,
      data: [],
      drawer: false,
      dataInfo: this.$store.state.payDataInfo,
      signDataInfo: [],
      ok: []
    }
  },
  created() {
    this.getProductView()
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    handleNext() {
      let params = {
        deptId: this.deptId,
        userId: this.userId,
        productId: this.productId
      }
      getWaitSignContractList(params).then(res => {
        if (res.code == 200) {
          this.drawer = true
          this.signDataInfo = res.dataInfo
          this.checkSign()
        } else {
          this.$message.error(res.msg)
        }
      })

      // this.$router.push('/step3');
    },
    getProductView() {
      getProductInfo(this.productId).then(res => {
        if (res.code == 200 && res.dataInfo != null) {
          this.data = res.dataInfo
        }
      })
    },
    handleBankNum() {
      if (this.dataInfo.bankCard) {
        let num = this.dataInfo.bankCard.toString()
        return '尾号' + num.substring(num.length - 4, num.length)
      }
    },
    handleClose(done) {
      this.drawer = false
    },
    handleSign(item) {
      this.loading = true
      let docTemplateId = item.docTemplateId
      let params = this.dataInfo
      params.docTemplateId = docTemplateId
      params.type = 0
      startSign(params).then(res => {
        this.loading = false
        if (res.code == 200) {
          let url = res.dataInfo.url
          let okDataInfo = {
            url: res.dataInfo.url,
            processId: res.dataInfo.processId,
            docId: item.docTemplateId
          }
          let okSign = this.$store.state.okSign
          okSign.push(okDataInfo)
          this.$store.commit('setOkSign', okSign)
          window.open(url, '_self');
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    checkSign() {
      let okSign = this.$store.state.okSign
      for (let i = 0; i < this.signDataInfo.length; i++) {
        for (let j = 0; j < okSign.length; j++) {
          if (this.signDataInfo[i].docTemplateId == okSign[j].docId) {
            checkUserSign({userId: this.userId, productId: this.productId, id: okSign[j].processId}).then(res => {
              if (res.code == 200) {
                this.ok.push(okSign[j].processId)
              }
            })
          }
        }
      }
    },
    handleCheckSign() {
      if (this.ok.length >= this.signDataInfo.length) {
        this.$router.push('/step3')
        this.$store.commit('step3', [])
      } else {
        this.$message.error('请先完成所有签署')
      }
    },
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}

.card1 {
  height: 140px;
  background: linear-gradient(90deg, rgba(246, 218, 184, 1) 0%, rgba(235, 184, 128, 1) 100%);
  border-radius: 12px;
  padding: 10px;
}

.card1-text1 {
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.card1-text2 {
  margin-top: 40px;
  font-size: 36px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.card2 {
  margin-top: 20px;
  height: 230px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.img-EtP-7 {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.card2-text1 {
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card2-text2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card2-text3 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  text-align: right;
}

.card2-img {
  width: 13px;
  height: 13px;
}

.card2-span {
  font-size: 12px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}

.card3 {
  margin-top: 20px;
  height: 150px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.card3-text1 {
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card3-text2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card3-text3 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  text-align: right;
}

.but-next {
  margin-top: 20px;
  width: 300px;
  height: 50px;
  border-radius: 37px;
  background: linear-gradient(135deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
}

.but-text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

::v-deep .el-drawer__header {
  font-weight: 800;
  color: rgba(56, 56, 56, 1);
}

.drawer-text1 {
  text-align: left;
  transition: background-color 0.3s; /* 变色过渡效果 */
}

.drawer-div {
  height: 40px;
  transition: background-color 0.3s; /* 变色过渡效果 */
}

.drawer-div:active {
  background-color: rgb(207, 206, 206); /* 鼠标点击时的背景色 */
}
</style>
