<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">投资者声明</span>
    </header>

    <div class="body">
      <div class="card1">
        <el-row>
          <el-col :span="2">
            <el-checkbox class="body-checkbox" v-model="check1"/>
          </el-col>
          <el-col :span="22">
            <span style="color: rgb(166, 166, 166);" @click="handleCheckClick(1)" class="span-active">
              作为 "{{ productName }}" 投资者，本人/机构作为本计划的投资者，本人/机构已充分了解并谨慎评估自身风险承受能力，
              自愿自行承担投资该计划所面临的风险。本人/机构做出以下陈述和说明，并确认起内容的真实和正确。
            </span>
          </el-col>
        </el-row>
      </div>
      <div class="card2">
        <div class="card2-text1">请逐项勾选以下声明内容(共3条)</div>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="2">
            <el-checkbox class="body-checkbox" v-model="check2"/>
          </el-col>
          <el-col :span="22">
            <span style="color: rgb(166, 166, 166);" @click="handleCheckClick(2)" class="span-active">
              1、本人/机构已仔细阅读资产管理业务相关法律文件和其他文件，充分理解相关权利、
              义务、本计划运作方式及风险收益特征，愿意承担上述风险引致的全部后果。
            </span>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="2">
            <el-checkbox class="body-checkbox" v-model="check3"/>
          </el-col>
          <el-col :span="22">
            <span style="color: rgb(166, 166, 166);" @click="handleCheckClick(3)" class="span-active">
              2、本人/机构知晓管理人、销售机构、托管人及相关机构不应当对资产管理计划财产的收益状况做出任何承诺或担保。
            </span>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="2">
            <el-checkbox class="body-checkbox" v-model="check4"/>
          </el-col>
          <el-col :span="22">
            <span style="color: rgb(166, 166, 166);" @click="handleCheckClick(4)" class="span-active">
              3、本人/机构已认真阅读并同意资产管理合同的所有内容，并愿意自行承担购买资产管理计划的法律责任。
            </span>
          </el-col>
        </el-row>
      </div>

      <el-button class="but-next" @click="handleOk">
        <div class="but-text">确认</div>
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementIndex',
  props: {
    formData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      productName: null,
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    }
  },
  created() {
    if (this.formData) {
      if (this.formData.productName) {
        this.productName = this.formData.productName
      }
    }
  },
  methods: {
    handleBack() {
      if (this.check1 && this.check2 && this.check3 && this.check4) {
        this.$router.push({
          name: 'step1',
          params: {
            formData: {
              money: this.formData.money,
              statementType: true,
              companyType: this.formData.companyType,
              bankInfo: this.formData.bankInfo
            }
          }
        })
      } else {
        this.$router.push({
          name: 'step1',
          params: {
            formData: {
              money: this.formData.money,
              statementType: false,
              companyType: this.formData.companyType,
              bankInfo: this.formData.bankInfo
            }
          }
        })
      }
    },
    handleOk() {
      if (this.check1 && this.check2 && this.check3 && this.check4) {
        this.$router.push({
          name: 'step1',
          params: {
            formData: {
              money: this.formData.money,
              statementType: true,
              companyType: this.formData.companyType,
              bankInfo: this.formData.bankInfo
            }
          }
        })
      } else {
        this.$message.error('请勾选所有声明内容')
      }
    },
    handleCheckClick(type) {
      switch (type) {
        case 1:
          this.check1 = !this.check1
          break
        case 2:
          this.check2 = !this.check2
          break
        case 3:
          this.check3 = !this.check3
          break
        case 4:
          this.check4 = !this.check4
          break
      }
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}

.card1 {
  //height: 110px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  //margin-top: 10px;
  padding: 10px;
}

.card2 {
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  margin-top: 10px;
  padding: 10px;
}

.card2-text1 {
  text-align: left;
}

.img-EtP-7 {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.but-next {
  margin-top: 20px;
  width: 300px;
  height: 50px;
  border-radius: 37px;
  background: linear-gradient(135deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
}

.but-text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

::v-deep .el-checkbox__inner {
  border-radius: 20px;
}

.span-active {
  transition: background-color 0.3s;
}

.span-active:active {
  background-color: rgb(207, 206, 206); /* 鼠标点击时的背景色 */
}
</style>
