import request from '@/utils/request'

//基于产品获取所有属于该产品待签约合同文件
export function getWaitSignContractList (data) {
  return request({
    url: '/api/news-finance/signContract/getWaitSignContractList',
    method: 'get',
    params: data
  })
}

//基于产品获取所有属于该产品免责协议
export function getWaitDisclaimerList (data) {
  return request({
    url: '/api/news-finance/disclaimer/getWaitDisclaimerList',
    method: 'get',
    params: data
  })
}

// 基于合同模板发起文件签署
export function startSign(data) {
  return request({
    url: '/api/news-finance/signContract/createContractFile',
    method: 'post',
    data: data
  })
}

//检查是否签约
export async function checkUserSign (data) {
  return request({
    url: '/api/news-finance/userSign/checkUserSign',
    method: 'get',
    params: data
  })
}
