<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">添加银行卡</span>
    </header>

    <div class="body">
      <div class="card-add">
        <div class="card-add-text1"><div style="color: rgba(255, 3, 3, 1)">*</div>请准确填写银行卡信息</div>
        <el-row class="card-row">
          <el-col :span="6">
            <div class="card-add-text2">姓名</div>
          </el-col>
          <el-col :span="18">
            <div class="card-add-text3">{{form.userName}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row class="card-row">
          <el-col :span="6">
            <div class="card-add-text2">身份证号</div>
          </el-col>
          <el-col :span="18">
            <div class="card-add-text3">{{form.idCard}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row class="card-row">
          <el-col :span="6">
            <div class="card-add-text4">银行卡号</div>
          </el-col>
          <el-col :span="18">
            <el-input placeholder="请输入你的银行卡号码" v-model="form.bankNum" @input="handleBank"/>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row class="card-row">
          <el-col :span="6">
            <div class="card-add-text4">银行名称</div>
          </el-col>
          <el-col :span="18">
            <el-input placeholder="请输入你的银行卡号后将自动识别" v-model="form.bankName"/>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row class="card-row">
          <el-col :span="6">
            <div class="card-add-text4">开户行</div>
          </el-col>
          <el-col :span="18">
            <el-input placeholder="请输入你的开户行" v-model="form.bankAddress"/>
          </el-col>
        </el-row>
      </div>

      <el-button class="but-bd" @click="handleCommit">
        <div class="but-text">确认绑定</div>
      </el-button>
    </div>
  </div>
</template>
<script>
import {getMyIdCardAuthenInfoV2} from '@/api/authen/authen'
import {addBank} from '@/api/bank/bank'
import {bankCardAttribution} from '@/utils/bankcardinfo'
export default {
  name: 'AddBank',
  props: {
    formData: {
      type: Object,
      default: null
    }
  },
  data() {
    return{
      userId: this.$store.state.userId,
      form: {
        userName: null,
        idCard: null,
        bankNum: null,
        bankName: null,
        bankAddress: null,
        simpleName: null,
        bankCardType: null
      }
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    handleBack() {
      if (this.formData) {
        if (this.formData) {
          this.$router.push({ name: "step1", params: {formData: {
                money: this.formData.money,
                statementType: this.formData.statementType,
                companyType: this.formData.companyType,
                bankInfo: this.formData.bankInfo
              }
            }})
        }
      } else {
        this.$router.go(-1);
      }
    },
    getInfo() {
      getMyIdCardAuthenInfoV2({userid: this.userId, authentype: 0}).then(res => {
        if (res.code == 200) {
          if (res.dataInfo != null) {
            let dataInfo = res.dataInfo
            this.form.userName = dataInfo.username
            this.form.idCard = dataInfo.usernum
          }
        }
      })
    },
    handleBank(value) {
      let data = bankCardAttribution(value)
      if (data != 'error') {
        this.form.bankName = data.bankName
        this.form.simpleName = data.bankCode
        this.form.bankCardType = data.cardType
      }
    },
    handleCommit() {
      let params = {
        userId: this.userId,
        name: this.form.userName,
        idCardNo: this.form.idCard,
        bankCard: this.form.bankNum,
        bankName: this.form.bankName,
        bankAddress: this.form.bankAddress,
        simpleName: this.form.simpleName,
        bankCardType: this.form.bankCardType
      }
      addBank(params).then(res => {
        if (res.code == 200) {
          this.$message.success('绑定成功')
          this.handleBack()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}

</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}

.card-add {
  height: 380px;
  border-radius: 18px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.card-add-text1 {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: rgba(59, 6, 6, 1);
  text-align: left;
}

.img-EtP-7 {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.card-row {
  margin-top: 20px;
}

.card-add-text2 {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}

.card-add-text3 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.card-add-text4 {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  margin-top: 10px;
}

.but-bd {
  margin-top: 20px;
  width: 300px;
  height: 50px;
  border-radius: 37px;
  background: linear-gradient(135deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);

}

.but-text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

::v-deep .el-input__inner {
  border: 0px solid #DCDFE6;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
</style>
