<template>
  <div class="app">
    <header class="header">
<!--      <div class="back" @click="handleBack">-->
<!--        <i class="el-icon-arrow-left" style="left: 0"></i>-->
<!--      </div>-->
      <span class="title">交易结果</span>
    </header>

    <div class="body">
      <div class="card1">
        <img class="card1-img" src="@/assets/jyjg.png">
        <div class="card1-text1">申请成功，请及时转账完成交易</div>
        <div class="card1-step">
          <el-row style="margin-top: 20px;">
            <el-col :span="7">
              <div class="card1-img2">
                <img class="card1-step-1" src="@/assets/jyjgks.png">
              </div>
            </el-col>
            <el-col :span="17">
              <div class="card1-text2">申请开始时间：{{ getNowTime() }}</div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <div class="card1-img2">
                <img class="card1-step-2" src="@/assets/jyjgjx.png">
              </div>
            </el-col>
            <el-col :span="17">
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <div class="card1-img2">
                <img class="card1-step-1" src="@/assets/jyjgjs.png">
              </div>
            </el-col>
            <el-col :span="17">
              <div class="card1-text2">申请结束时间：{{getCurrentDateTimePlusDays(3)}}</div>
            </el-col>
          </el-row>
        </div>
        <div class="card1-text3">
          温馨提示：为避免交易失败，请确保使用交易时选择的银行卡进行转账，且转账金额与交易金额每笔对应
        </div>
      </div>

      <div class="card2">
        <div class="card2-text1">转账汇款流程</div>
        <div class="img-EtP-7"></div>
        <div class="card2-text2">转账方式: 线下转账</div>
        <el-row>
          <el-col :span="7">
            <div class="card-div-1"></div>
          </el-col>
          <el-col :span="8">
            <div class="card-div-2">收款账户信息</div>
          </el-col>
          <el-col :span="9">
            <div class="card-div-3"></div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 20px;">
          <el-col :span="12">
            <div class="card-div-4">收款户名</div>
          </el-col>
          <el-col :span="12">
            <div class="card-div-5">{{data.bankName}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="12">
            <div class="card-div-4">银行账户</div>
          </el-col>
          <el-col :span="12">
            <div class="card-div-5">{{data.bankCard}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="12">
            <div class="card-div-4">开户行</div>
          </el-col>
          <el-col :span="12">
            <div class="card-div-5">{{data.bankAddress}}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="12">
            <div class="card-div-4">转账金额</div>
          </el-col>
          <el-col :span="12">
            <div class="card-div-5">￥ {{payDataInfo.money}} (元)</div>
          </el-col>
        </el-row>
      </div>

      <el-button class="but-next" @click="handleNext">
        <div class="but-text">确认</div>
      </el-button>
    </div>
  </div>
</template>
<script>
import {getProductInfo} from "@/api/product/product";

export default {
  name: 'BuyStep3',
  data() {
    return {
      productId: this.$store.state.productId,
      payDataInfo: this.$store.state.payDataInfo,
      data: {}
    }
  },
  created() {
    this.getProductView()
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    handleNext() {
      this.$router.push('/basLoginDisplay')
    },
    getProductView() {
      getProductInfo(this.productId).then(res => {
        if (res.code == 200 && res.dataInfo != null) {
          this.data = res.dataInfo
        }
      })
    },
    getNowTime() {
      const date = new Date();
      return this.formatDateTime(date)
    },
    getCurrentDateTimePlusDays(days) {
      const date = new Date();
      date.setDate(date.getDate() + days);
      return this.formatDateTime(date);
    },
    formatDateTime(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
  },
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}

.card1 {
  text-align: center;
  margin-top: 20px;
}

.card1-img {
  width: 69px;
  height: 69px;
}

.card1-text1 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}

.card1-step {
  text-align: center;
}

.card1-step-1 {
  width: 13.13px;
  height: 13.13px;
}

.card1-img2 {
  text-align: right;
}

.card1-text2 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  text-align: left;
  margin-left: 20px;
}

.card1-step-2 {
  width: 1px;
  height: 22.41px;
  margin-right: 6px;
}

.card1-text3 {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  text-align: center;
}

.card2 {
  margin-top: 20px;
  padding: 10px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
}

.card2-text1 {
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}

.img-EtP-7 {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.card2-text2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
  text-align: left;
}

.card-div-1 {
  margin-top: 10px;
  height: 0.1px;
  border: 3px solid transparent;
  background-image: linear-gradient(to right, rgba(212, 48, 48, 0), rgba(212, 48, 48, 1) 100%);
  border-radius: 8px;
}

.card-div-2 {
  width: 100%;
  height: 25px;
  border-radius: 92px;
  background: rgba(212, 48, 48, 1);
  color: white;
}

.card-div-3 {
  margin-top: 10px;
  height: 0.1px;
  border: 3px solid transparent;
  background-image: linear-gradient(to right, rgba(212, 48, 48, 1), rgba(212, 48, 48, 0) 100%);
  border-radius: 8px;
}

.card-div-4 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card-div-5 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
  text-align: right;
}

.but-next {
  margin-top: 20px;
  width: 300px;
  height: 50px;
  border-radius: 37px;
  background: linear-gradient(135deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
}

.but-text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
</style>
