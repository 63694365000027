import request from "@/utils/request";

// 校验用户是否认证
export function userIsAuth(data) {
  return request({
    url: '/api/news-app-user-api/api/userIsAuthenV2',
    method: 'get',
    params: data,
  })
}

// 实名认证
export function upIdCardAuthen(data) {
  return request({
    url: '/api/news-app-user-api/api/upIdCardAuthen',
    method: 'post',
    data: data
  })
}

// 获取用户实名认证信息
export function getMyIdCardAuthenInfoV2(data) {
  return request({
    url: '/api/news-app-user-api/api/getMyIdCardAuthenInfoV2',
    method: 'post',
    data: data,
  })
}
