import request from '@/utils/request'

//身份证ocr识别信息接口
export function idCardOcr(data) {
  return request({
    url: '/api/news-finance/api/est/idCardOcr',
    method: 'post',
    headers: {'Content-Type': 'multipart/form-data'},
    data: data
  })
}

//保存实名信息
export async function UpIdCardAuthenInfo(data) {
  return await request({
    url: '/api/user-api/api/upIdCardAuthen',
    method: 'post',
    data: data
  })
}

//判断是否已经实名
export async function userISAuthen() {
  return await request({
    url: '/api/user-api/api/userIsAuthen',
    method: 'get'
  })
}
//个人认证四要素-银行卡 姓名 手机号 身份证
export function authFactor4(data) {
  return request({
    url: '/api/news-finance/api/est/auth4Factors',
    method: 'post',
    data: data
  })
}

//个人认证三要素 姓名 手机号 身份证
export function authFactor3(data) {
  return request({
    url: '/api/news-finance/api/est/auth3Factors',
    method: 'post',
    data: data
  })
}

//个人认证三要素 短信验证码验证
export function authFactor3CodeValidate(data) {
  return request({
    url: '/api/news-finance/api/est/auth3Factors/codeValidate',
    method: 'get',
    params: data
  })
}

//个人认证三要素 再次发送短信验证码验证
export function authFactor3CodeResend(data) {
  return request({
    url: '/api/news-finance/api/est/auth3Factors/codeValidate/resend',
    method: 'get',
    params: data
  })
}
