import request from "@/utils/request";

/**
 * 查询用户签约记录
 * @param data
 * @returns {Promise<void>}
 */
export async function list(data) {
  return await request({
    url: '/api/news-finance/userSign/getUserSignList',
    method: 'get',
    params: data,
  })
}

// 获取该用户购买全部资产
export async function getUserTotalAsset(data) {
  return await request({
    url: '/api/news-finance/userSign/getUserTotalAsset',
    method: 'get',
    params: data,
  })
}
