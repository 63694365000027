<template>
  <div>
    <el-container>
      <el-card class="box-card" style="width: 100%;max-height:100%;background-color: white" v-loading="loading">
        <div slot="header" class="clearfix">
          <span style="font-weight: 700;color: black;">服务协议</span>
          <el-button style="float: left; color: black" type="text" @click="backTo" icon="el-icon-arrow-left"></el-button>
        </div>
        <div style="color: black;">
          <div>
            <a style="font-size: 18px; font-weight: bold">
              南京凡字码网络技术有限公司
            </a>
          </div>
          <div style="margin-top: 10px;">
            <a>
              用户服务协议
            </a>
          </div>
          <div style="text-align: left;margin-top: 10px;">
            <el-row>
              甲方：【投资者】
            </el-row>
            <el-row>
              乙方：南京凡字码网络技术有限公司（以下简称“乙方“或“本公司”）
            </el-row>
            <el-row>
              为了方便甲方在乙方办理基金交易业务，甲乙双方依据有关法律、法规的规定，本着公开、公平、自愿和诚实信用的原则，就乙方通过互联网服务平台为甲方提供网上交易服务及其他相关服务达成如下协议。
            </el-row>
            <el-row style="font-size: 18px;font-weight: bold">
              重要提示
            </el-row>
            <el-row>
              本协议在投资者与乙方之间具有合同上的法律效力，特此提醒您认真阅读和充分理解本协议各部分内容，特别是涉及到双方权利义务条款、风险提示、乙方免责条款、争议解决、法律适用等内容及加粗部分条款。
              请您审慎阅读并选择接受或不接受本协议，您通过网络勾选或确认本协议，即表明您已经仔细阅读及同意本协议各项内容，并视为对本协议全部条款已充分理解并完全接受。为了保障服务的正常提供或符合监管要求，与乙方有关的已经发布或将来可能不时发布的各项规则、页面展示、操作流程、公告或通知，也是本协议的重要组成部分。如本协议发生变更，导致您的权利义务发生变化，我们将通过本公司网站等互联网平台公告的方式予以公布或者其他合理的方式向您告知，请您留意变更后的协议内容。如您在本协议变更生效后，继续使用乙方平台服务的，表示您愿意接受变更后的协议，也将遵循变更后的协议使用乙方提供的相关服务。
            </el-row>
            <el-row style="font-size: 18px;font-weight: bold;margin-top: 10px;">
              第一条 释义
            </el-row>
            <el-row>
              除本协议另有规定外，下列用语在本协议中具有如下含义：
            </el-row>
            <el-row>
              1、管理人：指各类资产管理机构，包括基金管理公司及子公司、资产管理公司、证券公司及子公司、期货公司及子公司、银行理财子公司、私募基金管理公司等。
            </el-row>
            <el-row>
              2、基金：指各类型管理人发行的各种投资理财产品。包括公募证券投资基金、资产管理计划、私募证券投资基金，以及公司有权销售的其他投资理财产品。
            </el-row>
            <el-row>
              3、网上交易：指甲方通过乙方网上交易系统进行基金交易、信息查询或其他相关业务的交易方式。
            </el-row>
            <el-row>
              4、基金交易账户：指乙方为甲方开立的，记录其通过乙方进行交易的基金份额的变动及结余情况的账户。
            </el-row>
            <el-row>
              5、基金账户：指基金注册登记机构为投资者开立的，记录其持有基金份额、份额变更情况及基本资料的账户。
            </el-row>
            <el-row>
              6、基金账号登记：如甲方在乙方以外的其他基金销售机构开立基金账户后，需在乙方办理基金业务时，应首先登记基金账号。
            </el-row>
            <el-row>
              7、认购：指基金募集发售期内，投资者申请购买基金份额的行为。
            </el-row>
            <el-row>
              8、申购：指基金合同生效后，投资者申请购买基金份额的行为。
            </el-row>
            <el-row>
              9、赎回：指基金份额持有人按基金合同规定的条件要求基金管理人购回基金份额的行为。
            </el-row>
            <el-row>
              10、基金转换：指基金份额持有人将其所持有的基金份额转换成其它基金的基金份额的行为。
            </el-row>
            <el-row>
              11、基金份额转托管转入：指基金份额持有人将其持有的基金份额从其它基金销售机构的交易账户转到在乙方开立的交易账户进行交易的行为。
            </el-row>
            <el-row>
              12、分红方式变更：指变更红利发放方式的行为，红利发放可以采用现金分红方式或红利再投资方式。
            </el-row>
            <el-row>
              13、交易撤单：指将已生效的委托在规定时间内进行撤销的行为。
            </el-row>
            <el-row>
              14、账户资料修改：指甲方修改其在乙方系统记录的个人联系信息及其它可修改信息的行为。
            </el-row>
            <el-row>
              15、工作日：指上海证券交易所、深圳证券交易所的正常交易日。
            </el-row>
            <el-row style="font-size: 18px;font-weight: bold;margin-top: 10px;">
              第二条 网上交易服务的内容
            </el-row>
            <el-row>
              1.本协议所述网上交易服务包括基金交易账户开户、基金账户开户、基金账户登记、银行账户变更、账户资料修改、认购、申购、赎回、基金份额转托管转入、基金转换、分红方式变更、交易撤单和查询等业务的服务。
            </el-row>
            <el-row>
              2.甲方的认购、申购、赎回、基金转换等交易行为应按照基金合同和基金招募说明书的有关规定缴纳相应的费用，并应承担因上述交易行为发生的资金划付费用等银行相关费用。
            </el-row>
            <el-row style="font-size: 18px;font-weight: bold;margin-top: 10px;">
              第三条 网上交易风险提示
            </el-row>
            <el-row>
              1、基金投资存在损失本金的风险，甲方申请使用乙方网上交易系统进行基金交易将被认为已经完全了解基金投资的风险，并能够承担由此可能带来的损失。
            </el-row>
            <el-row>
              2、乙方已最大限度地采取了合理措施保护甲方资料和交易活动的安全。尽管如此，本着对客户负责的态度，乙方在此郑重提示甲方进行网上交易仍然存在风险。该风险包括但不限于：
            </el-row>
            <el-row>
              （1）互联网是全球公共网络，并不受任何一个机构所控制。数据在互联网上传输的途径不是完全确定的，可能会受到非法干扰或侵入。
            </el-row>
            <el-row>
              （2）在互联网上传输的数据有可能被某些个人、团体或机构通过某种渠道获得或篡改。
            </el-row>
            <el-row>
              （3）互联网上的数据传输可能因通信繁忙出现延迟，或因其他原因出现中断、停顿或数据不完全、数据错误等情况，从而使交易出现延迟、停顿或中断。
            </el-row>
            <el-row>
              （4）互联网上发布的各种信息，包含但不限于分析、预测性资料等，均可能出现错误或发生误导。
            </el-row>
            <el-row>
              （5）甲方的网上交易身份可能会被仿冒。
            </el-row>
            <el-row>
              （6）甲方自身所使用计算机的性能、质量、病毒、故障或其他原因可能对甲方的交易时间或交易数据造成影响，因此给甲方造成损失。
            </el-row>
            <el-row>
              （7）甲方计算机应用操作能力或互联网知识方面的缺乏，可能对甲方的交易时间或交易数据造成影响，因此给甲方造成损失。
            </el-row>
            <el-row>
              （8）因甲方自身的疏忽造成的账户信息或密码（包括但不限于交易密码或银行卡支付密码，以下同）泄漏可能会给甲方造成损失。
            </el-row>
            <el-row>
              （9）因不可抗力事件导致的其他网上交易风险。
            </el-row>
            <el-row style="font-size: 18px;font-weight: bold;margin-top: 10px;">
              第四条 甲方在此作出以下声明、保证和承诺：
            </el-row>
            <el-row>
              1、甲方声明：已按照乙方的要求在乙方开立了基金交易账户，并已作出以下承诺：“本人/本机构为中华人民共和国法律法规以及各基金合同规定的合法投资者。
            </el-row>
            <el-row>
              2、甲方声明：自愿通过乙方的网上交易系统办理基金业务并同意：任何登录乙方网上交易系统所进行的网上交易行为均应被视同为甲方亲临乙方柜台办理相应的基金业务。
            </el-row>
            <el-row>
              3、甲方声明：在签订本协议之前已经详细阅读了本协议全部规定，包括乙方免责规定，准确理解了其含义，清楚地了解了使用网上交易可能遭受的各种风险。甲方自愿承担因此类风险导致的任何损失，不对因此类风险导致的任何损失向乙方请求赔偿。
            </el-row>
            <el-row>
              4、甲方声明：在签订本协议之前已了解同意关于基金产品的相关全部信息以乙方销售人员或乙方网站介绍为准，并已详细阅读、理解并接受乙方所代销的基金的《基金合同》、《招募说明书》、更新的招募说明书及相关业务规则的全部规定。充分了解了基金投资的规则和风险，在购买基金前已充分考虑了自身的年龄、经济实力、投资目的、投资期限、收益预期和风险承受能力等情况，客观评估了自身的风险承受能力，认真确定了自身的投资目标和策略，并挑选了与自身风险承受能力相匹配的基金产品。
            </el-row>
            <div style="float: left">


              5、甲方保证：用于投资的资金来源及用途合法，不存在任何瑕疵，并承诺未使用贷款、发行债券等筹集的非自有资金进行投资，并承诺自行承担因违反本条规定引起的一切法律责任和全部赔偿责任。

              6、甲方保证：在任何情况下根据乙方要求所填写的信息，包括但不限于登录乙方网上交易系统时填写的姓名（机构名称）、身份证件号码（机构识别代码）、银行卡号（账号）等相关信息，是真实、准确和有效的。如所填写信息发生变化，甲方将及时办理相关信息及资料的变更。甲方承诺，因甲方提供不真实、错误或无效信息，或因未能及时变更相关信息和资料所导致的任何损失，由甲方自行承担；如因此导致对乙方或其他任何第三方的损失，亦由甲方承担全部赔偿责任。

              7、甲方有权且仅有权根据本协议的规定，以甲方自身的名义并为甲方自身之利益登录乙方网上交易系统，进行网上交易。甲方承诺，不以其自身名义通过网上交易系统代理任何其他第三方进行基金交易或从中收取任何费用，亦不应允许任何其他人以甲方的名义通过乙方网上交易系统进行网上交易，否则均视为甲方自身之行为，由此产生的任何风险和责任均由甲方自行承担，与乙方无关。

              8、甲方承诺：对自己的账户信息和密码认真履行保密义务，采取合理措施保护自己的账户信息和密码。因任何甲方原因导致账户信息或密码泄露给甲方造成损失，甲方承担全部责任。甲方采取的保密措施包括但不限于：

              （1）定期更换密码；

              （2）不将账户信息和密码记载在任何他人可以看到的载体上；

              （3）不将账户信息和密码告知任何第三方；

              （4）发现或有理由认为自己的网上交易账户信息和密码已经或可能被第三方获取或使用，立即修改密码或采取其他防范措施。

              甲方在此同意并确认，通过甲方账号，使用甲方密码提交的一切网上交易申请均应被视为甲方本人/本机构提出并亲自办理的有效交易申请，乙方可以充分信赖该等交易申请而受理，甲方承认该等申请及其所有结果对甲方具有约束力。

              9、为保护甲方交易数据的安全，甲方在进行网络交易时应使用乙方指定的浏览器。甲方承诺，因使用不符合要求的其他软件或设备进入乙方网站而引起的任何损失、损害或其他任何后果，均由甲方自行承担，乙方不需对此承担任何责任。

              10、甲方承诺：确保用于网上交易的甲方设备的安全性和可靠性，并自行承担因甲方设备故障及通讯故障等原因造成的任何损失，乙方不需对此承担任何责任。

              11、甲方承诺：不将本协议项下的权利或义务部分或全部地转让给任何第三方。

              12、甲方承诺：不以任何方式从事任何可能对乙方网站或其网上交易系统造成损害的行为，违反本条规定给乙方或任何其他第三方造成损害或损失，甲方承担全部赔偿责任。

              13、甲方承诺：对因甲方违约行为导致乙方遭受的任何损失承担全部赔偿责任。

              14、甲方同意：授权乙方，除法律另有规定之外，将甲方提供到乙方的信息、享受乙方服务产生的信息（包括本协议签署之前提供和产生的信息）以及乙方根据本条约定查询、收集的信息，用于乙方及其因服务必要委托的合作伙伴为用户提供服务、推荐产品、开展市场调查与信息数据分析。

              15、甲方声明，根据《非居民金融账户涉税信息尽职调查管理办法》中相关规定，甲方仅为中国税收居民。甲方保证上述相关信息变化之日起三十日内告知乙方。

              如甲方向乙方另行提交其他资料进行非居民身份证明的，以甲方提供的资料为准。

              16、甲方承诺，甲方如购买非公募产品的，需符合《证券期货经营机构私募资产管理计划运作管理规定》《关于规范金融机构资产管理业务的指导意见》《私募投资基金监督管理暂行办法》等相关规定中的合格投资者要求，并按照乙方要求向乙方提供相应资料、凭证等证明文件。

              17、甲方承诺，甲方签署、履行本协议及对外投资所需的所有必要的内部的批准（包括但不限于董事会、股东会/股东大会、投资决策委员会等的批准）、审批机关的批准（如须）均已获得且未被撤销。同时，甲方承诺，甲方签署、履行本协议及对外投资行为不会与甲方承担的其他合同义务相冲突，也不会违反有关法律法规、规章制度和公司内部管理制度的规定（适用于机构投资者）。

              18、甲方同意，因甲方身份证件有效期过期、涉嫌可疑交易、涉及身份重新识别业务场景未按乙方要求提供补充材料、触发管控名单等原因，乙方有权根据反洗钱相关法律法规要求暂停或终止为甲方提供本协议项下服务，乙方不对由此导致的甲方损失（包括但不限于因丧失投资机会而未能取得投资收益）承担任何责任。

              19、如甲方在使用乙方提供的销售服务中对乙方负有到期债务（包括但不限于因乙方向甲方垫资出款、甲方买入成功支付失败、甲方重复赎回份额、或者乙方系统重复划款等导致的甲方不当得利或其他负债），甲方知晓该等债务属于到期债务，甲方负有立即向乙方偿还款项的义务。甲方授权乙方经通知甲方后，乙方有权采取包括但不限于调减甲方基金份额、冻结甲方交易账户、自动还款、发函催告等方式来实现债权。

              第五条 乙方承诺

              1、乙方将严格遵守所适用的法律法规，并接受本协议所有条款的约束。

              2、乙方网上交易系统的系统安全、数据备份和故障恢复措施符合监管机关的规定。

              3、乙方将采取先进的网络产品和合理有效的技术措施，最大限度地保护客户资料和交易活动的安全。

              4、乙方将根据行业标准严格保密甲方注册时所填写的资料信息，使甲方的信息处于安全状态，未经甲方的同意乙方不得恶意出售或免费共享给任何第三方（公司或个人）。但以下情况除外：

              （1）甲方授权网站公开、透露这些信息；

              （2）相关的法律法规或监管机构、司法机构要求乙方提供甲方的个人资料；国家司法机关符合法律规定并经法定程序的检查及其他操作；

              （3）任何第三方盗用、冒用或未经许可擅自披露、使用或对外公开用户的个人隐私资料。

              （4）乙方自己要求网站提供特定服务时，需要把乙方的姓名和地址提供给第三方的。

              （5）乙方的关联实体。

              （6）在适合的情况下，并在甲方同意的前提下，乙方会利用甲方的信息来联络甲方，为甲方发送信息。

              5、对甲方的网上交易行为，乙方将保留相关电子数据作为甲方交易行为的证明，对甲方的委托资料、委托事项、密码及其他在乙方登记的资料履行保密义务，未经甲方许可不向任何人透露，但乙方按照有关法律、法规规定或有关司法机关、行政管理机关的要求提供甲方有关资料的行为除外。

              6、乙方在本协议生效当日为甲方开通本协议约定的交易委托方式。

              7、乙方依据基金销售适当性原则，对甲方的交易申请进行销售适当性判断，如出现风险不匹配的情况，乙方将以醒目的方式提示甲方，告知其交易风险。

              第六条 提示条款

              1、本协议的签署：甲方需在办理网上基金交易账户开户业务、网上交易业务前签署订本协议。 甲方须与乙方通过乙方平台签订本协议，在进行网上交易过程中，甲方若点击确认，则视为甲方已仔细阅读并理解本协议，效力等同于甲、乙双方签字盖章。

              2、网上基金交易账户开户、银行账户变更业务：在办理甲方网上基金交易账户开户或银行账户变更业务时，乙方会通过相关合作方对甲方的身份信息、银行账户信息进行核对，核对结果以合作方提供的确认信息为准。如因合作方提供的确认信息有误，导致甲方不能正常办理前述业务的，乙方对此不承担任何责任。

              3、支付业务提示：甲方须依据乙方业务规则及相关业务指南办理资金支付事宜。

              4、设备提示：甲方应有本协议约定的，网上交易所必需的设备。

              5、密码提示：甲方在登录网上交易时必须录入登录密码，在进行网上交易时必须录入交易密码。

              6、交易提示：

              (1) 甲方通过网上交易方式下达的交易申请以乙方网上交易系统的记录为准。

              (2) 甲方使用网上交易方式办理基金业务，需自行支付因此产生的相关费用，包括但不限于上网费、甲方发起的银行划款手续费等。

              (3) 乙方受理的甲方各类需要注册登记机构确认的基金业务申请，以注册登记机构确认的结果为准。

              7、交易受理时间提示：

              基金当日交易申请的受理截止时间为15：00，如在当日15：00后提交交易申请，则该交易申请将作为下一个工作日的交易申请处理。交易申请的提交时间以乙方网上交易系统自动记载时间为准。

              8、投资者信息收集提示：乙方遵循合法、正当、必要和诚信原则，为实现客户身份验证及基金交易的其他功能，在开户过程中将收集甲方相关必要信息（个人投资者包括甲方的姓名、手机号码、证件类型、证件号码、银行卡号等相关必要信息；机构投资者包括甲方的名称、营业执照或其他可证明甲方依法设立或者可依法开展经营、社会活动的执照、银行账户信息、法定代表人身份信息、控股股东或实际控制人身份信息、授权代理人身份信息、授权文书、联系方式等相关必要信息），甲方为了顺利进行开户及交易需填写真实有效的信息并承诺愿意通过短信、邮件、APP 推送、站内信等方式接收包括但不限于交易确认短信、分红确认短信、对账单、产品信息、产品宣传推介、营销推广信息等信息，及获得其他类似服务，甲方可通过退订等方式在相关操作界面取消该等推送。

              9、第三方身份验证提示：为核实甲方身份及信息资料真实性，甲方申请开户、重置交易密码、变更赎回款到账银行卡或进入其他乙方基于反洗钱要求需进行客户身份识别的业务场景时，甲方特授权乙方将甲方提交的各项资料信息（包括但不限于甲方身份证件信息、银行账户信息、联系方式、面部等生物特征等各项资料）传输给甲方绑定银行卡开户银行或者其他第三方信息认证服务商进行真实性验证。乙方将要求第三方信息认证服务商严格履行保密义务。若开户银行或第三方信息认证服务商认证失败的，乙方有权拒绝甲方的业务申请，甲方知晓并自愿承担相关后果。

              10、电话录音提示：为作为日后核查的依据，乙方可能对双方电话通话内容进行录音，甲方同意进行电话录音并认可该录音是双方交易真实有效的证据，甲方承诺在任何司法程序中不对该电话录音提出任何形式的质疑。

              第七条 免责条款

              双方特别约定，乙方对以下情形以及以下情形导致的损失不承担责任：

              1、因战争、自然灾害、罢工、不可控制的流行疾病、地震、火灾、台风及其他各种不可抗力事件引起的停电、网络系统故障及电脑故障。

              2、因电信部门的通讯线路故障、通讯技术缺陷、电脑黑客或计算机病毒等原因造成的交易系统不能正常运转。

              3、因法律、政策发生重大变化，或乙方不可预测、不可控制的因素引起突发事件导致甲方损失的。

              4、因技术问题（包括但不限于电脑软件、通讯、网络传输等故障）引起的交易失败导致甲方损失的。

              5、因银行划付等业务故障引起的交易失败导致甲方损失的。

              6、因甲方设备或通讯故障或设备未能处于正常工作状态致使乙方未能按时或及时收到甲方的申请信息，或者乙方收到的甲方申请信息不完整，从而导致甲方损失的。

              7、因甲方对设备的错误操作和对有关信息的错误理解导致甲方损失的。

              8、因甲方轻信非乙方或非乙方工作人员提供的有关信息（包括但不限于产品信息等）而导致甲方损失的。

              9、乙方对甲方所有的网上交易申请仅进行表面真实性审核。通过甲方账号、密码提交的网上交易申请，只要通过表面真实性审核，乙方均可确认为甲方本人/本机构提出，给予充分信赖而进行受理。甲方由于自身疏忽或其他非乙方原因导致账户信息或密码（包括但不限于交易密码或银行卡支付密码）失密，并因此受到损失，应自行承担，乙方不需对此承担任何责任。

              10、因甲方姓名或名称中含有生僻字，导致取现失败或延迟到款的。

              11、如因产品或服务暂停申购、申购限额等管理人或其他第三方原因导致交易申请受理全部或部分失败的，乙方不承担任何责任。

              12、法律规定和本协议约定的其他免除乙方责任的事项。

              第八条 协议生效及变更

              1、本协议自甲方在乙方网上交易系统中对本协议予以确认之日起生效。

              2、在不损害甲方利益的前提下，乙方享有修改或增补本协议内容的权利。本协议的修改通知以书面形式公告于乙方网站及营业场所，或以其他乙方认为可行的方式通知。甲方在修改通知公布或通知之日起三十日内未向乙方提出书面异议则视同乙方对本协议的修改已经得到甲方的同意和认可。

              3、本协议生效后，若有关法律、法规、行政规章、《基金合同》、《招募说明书》及其他乙方和甲方应共同遵守的文件发生修订，本协议中与之不相符合的规定自行失效，但本协议的其他规定继续有效。

              4、本协议在下述情况之一发生时终止：

              (1) 双方签订书面协议同意终止。

              (2) 甲方死亡或不再具备相应的民事行为能力。

              (3) 甲方撤销在乙方开立的交易账户。

              (4) 甲方取消网上交易方式。

              (5) 因不可抗力事件致使本协议无法继续履行。

              (6) 一方违约，守约方有权书面通知违约方终止本协议，本协议自书面通知发出之日起终止。

              第九条 争议的解决

              甲、乙双方因解释或履行本协议发生争议应尽可能通过协商解决。协商不成，任何一方均有权向中国国际经济贸易仲裁委员会申请仲裁，并按照该委员会届时有效的仲裁规则进行仲裁，仲裁地点在北京。仲裁裁决是终局的，对双方均有法律约束力。
            </div>
          </div>
        </div>
      </el-card>
    </el-container>
  </div>
</template>
<script>
export default {
  name: 'ServiceAgreement',
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    backTo() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">

</style>
