<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">{{content.name}}</span>
    </header>
    <div class="body">
      <div v-html="content.content">
      </div>
    </div>
  </div>
</template>
<script>
import {getWaitDisclaimerList} from '@/api/signContract/signContract';

export default {
  name: 'CompanyStatement',
  props: {
    formData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      deptId: this.$store.state.deptId,
      userId: this.$store.state.userId,
      productId: this.$store.state.productId,
      content: null,
    }
  },
  created() {
    this.handleGetWaitDisclaimerList()
  },
  methods: {
    handleBack() {
      this.$router.push({
        name: 'step1',
        params: {
          formData: {
            money: this.formData.money,
            statementType: this.formData.statementType,
            companyType: this.formData.companyType,
            bankInfo: this.formData.bankInfo
          }
        }
      })
    },
    handleGetWaitDisclaimerList() {
      getWaitDisclaimerList({userId: this.userId, productId: this.productId, deptId: this.deptId}).then(res => {
        if (res.code == 200) {
          this.content = res.dataInfo[0]
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}
</style>
