<template>
  <div class="app">
<!--    <img src="@/assets/hmback.png" class="vec-rectangle-vGs-4"/>-->
    <div class="card1">
      <el-row>
        <div class="span-rZo-1">资产管理</div>
      </el-row>
      <el-row style="margin-top: 20px;">
        <el-col :span="6">
          <div class="card1-div1">
            <span class="card1-text1">毫米平台</span>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="card1-div2">
            <span class="card1-text1">专业创造价值，真诚服务客户！</span>
          </div>
        </el-col>
      </el-row>
      <div class="card1-div3">
        <div style="display: flex">
          <span class="card1-text5">持有资产（元）</span>
          <div class="img-QfA-3"></div>
        </div>
        <div class="card1-text2">
          {{ userCount }}
        </div>
      </div>
      <div class="card1-text3">
        已为用户累计赚取1亿+
      </div>
      <el-row>
        <el-col :span="12">
          <div class="card1-text4">
            ·累计服务用户10万+
          </div>
        </el-col>
        <el-col :span="12">
          <div class="card1-text4">
            ·100+金融产品
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="body">
      <div class="card2">
        <el-row style="margin-top: 20px;">
          <el-col :span="8">
            <div @click="handleBank" class="drawer-div">
              <img src="@/assets/yhk.png">
              <div class="card2-text1">
                银行卡
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div @click="handleBill" class="drawer-div">
              <img src="@/assets/gmqd.png">
              <div class="card2-text1">
                购买清单
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div @click="handleAuthen" class="drawer-div">
              <img src="@/assets/smrz.png">
              <div class="card2-text1">
                实名认证
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div v-for="(item, index) in companyList" :key="index">
        <div class="card3">
          <el-row>
            <el-col :span="20">
              <div class="card3-text1">
                <span class="card3-text1-span1">{{ item[0].companyName }}</span>
                <span class="card3-text1-span2">|</span>
                <span class="card3-text1-span3">{{ item[0].createTime }}更新</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="card3-text2" @click="handleMore(item)">更多</div>
            </el-col>
          </el-row>
          <div class="img-EtP-7"></div>
          <div style="padding: 10px;">

            <div class="drawer-div">
              <div @click="handleView(item[0])">
                <div class="card3-text3">
                  {{ item[0].cpmc }}
                </div>
                <el-row style="margin-top: 20px;">
                  <el-col :span="8">
                  <span class="card3-text4">
                    {{ item[0].yjbcjtjz }}
                  </span>
                    <span class="card3-text6">%</span>
                    <div class="card3-text7">
                      计提基准
                    </div>
                  </el-col>
                  <el-col :span="8">
                  <span class="card3-text5">
                    {{ item[0].tzqx }}
                  </span>
                    <span class="card3-text8">天</span>
                    <div class="card3-text7">
                      投资期限
                    </div>
                  </el-col>
                  <el-col :span="8">
                  <span class="card3-text5">
                    {{ item[0].qtxe / 10000 }}
                  </span>
                    <span class="card3-text8">万</span>
                    <div class="card3-text7">
                      起投金额
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="img-EtP-7" v-if="item.length > 1"></div>

            <div class="drawer-div">
              <div v-if="item.length > 1" @click="handleView(item[1])">
                <div class="card3-text3">
                  {{ item[1].cpmc }}
                </div>
                <el-row style="margin-top: 20px;">
                  <el-col :span="8">
                  <span class="card3-text4">
                    {{ item[1].yjbcjtjz }}
                  </span>
                    <span class="card3-text6">%</span>
                    <div class="card3-text7">
                      计提基准
                    </div>
                  </el-col>
                  <el-col :span="8">
                  <span class="card3-text5">
                    {{ item[1].tzqx }}
                  </span>
                    <span class="card3-text8">天</span>
                    <div class="card3-text7">
                      投资期限
                    </div>
                  </el-col>
                  <el-col :span="8">
                  <span class="card3-text5">
                    {{ item[1].qtxe / 10000 }}
                  </span>
                    <span class="card3-text8">万</span>
                    <div class="card3-text7">
                      起投金额
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {indexList} from '@/api/product/product'
import {userIsAuth} from "@/api/authen/authen";
import {getUserTotalAsset} from "@/api/usersign/usersign";

export default {
  name: 'BasLoginDisplay',
  data() {
    return {
      token: this.$store.state.token,
      type: this.$route.params.type,
      companyList: [],
      userId: this.$store.state.userId,
      userCount: 0.00,
    }
  },
  created() {
    this.getIndexList()
    this.getUserCount()
  },
  methods: {
    handleLogin(type, deptId, productId) {
      this.$router.push('/login/' + type)
    },
    getUserAuthen(url) {
      userIsAuth({userId: this.userId}).then(res => {
        if (res.code == 200 && res.dataInfo) {
          this.$router.push(url)
        } else {
          this.$message.error('请先进行实名认证')
        }
      })
    },
    handleMore(item) {
      this.$store.commit('setDeptId', item[0].deptId)
      if (this.token == undefined) {
        this.handleLogin(1, item[0].deptId)
      } else {
        this.getUserAuthen('/product')
      }
    },
    handleView(item) {
      this.$store.commit('setDeptId', item.deptId)
      this.$store.commit('setProductId', item.id)
      if (this.token == undefined) {
        this.handleLogin(2)
      } else {
        this.getUserAuthen('/productInfo')
      }
    },
    handleBank() {
      if (this.token == undefined) {
        this.handleLogin(3)
      } else {
        this.getUserAuthen('/bank')
      }
    },
    handleBill() {
      if (this.token == undefined) {
        this.handleLogin(4)
      } else {
        this.$router.push('/order')
      }
    },
    handleAuthen() {
      if (this.token == undefined) {
        this.handleLogin(5)
      } else {
        this.$router.push('/authen')
      }
    },
    getIndexList() {
      indexList().then(res => {
        let data = res.dataInfo
        this.companyList = this.groupBy(data, 'companyName')
        console.log(this.companyList)
      })
    },
    getUserCount() {
      if (this.token != undefined) {
        getUserTotalAsset({userId: this.userId}).then(res => {
          if (res.code == 200) {
            this.userCount = res.dataInfo
          }
        })
      }
    },
    groupBy(array, key) {
      return array.reduce((result, currentItem) => {
        // 使用 key 函数提取分组的键，如果不提供，则直接使用属性名
        const groupKey = typeof key === 'function' ? key(currentItem) : currentItem[key];
        // 确保 result 对象中有对应分组的数组
        if (!result[groupKey]) {
          result[groupKey] = [];
        }
        // 将当前项添加到对应分组的数组中
        result[groupKey].push(currentItem);
        return result;
      }, {});
    }
  }
}
</script>

<style scoped>
.body {
  padding: 10px;
}

.card1 {
  z-index: 2;
  padding: 10px;
  height: 330px;
  background: linear-gradient(145.72deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
}

.card1-div1 {
  height: 24px;
  border-radius: 0px 10px 0px 10px;
  mix-blend-mode: normal;
  background: rgba(147, 101, 64, 1);
}

.card1-div2 {
  height: 24px;
  background-image: linear-gradient(90deg, rgba(56, 56, 56, 0.18) 1.39%, rgba(255, 214, 174, 0) 100%);
  mix-blend-mode: normal;
}

.span-rZo-1 {
  font-size: 25px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  margin-top: 20px;
}

.card1-text1 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}

.card1-div3 {
  padding: 10px;
  margin-top: 20px;
  height: 100px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.44);
}

.img-QfA-3 {
  width: 16px;
  height: 12px;
  background-image: url("@/assets/view.png");
  margin-top: 5px;
}

.card1-text2 {
  font-size: 30px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  margin-top: 20px;
}

.card1-text3 {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  color: rgba(254, 232, 214, 1);
  text-align: left;
  background-image: linear-gradient(90deg, rgba(142, 97, 62, 1) 0%, rgba(205, 149, 91, 1) 22.91%, rgba(145, 97, 50, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card1-text4 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(130, 88, 47, 1);
  text-align: left;
}

.card1-text5 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(128, 128, 128, 1);
}

.card2 {
  height: 80px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.card2-text1 {
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.card3 {
  margin-top: 10px;
  height: auto;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.card3-text1 {
  text-align: left;
}

.card3-text1-span1 {
  font-size: 17px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}

.card3-text1-span2 {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}

.card3-text1-span3 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}

.card3-text2 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(166, 166, 166, 1);
  text-align: right;
  transition: background-color 0.3s;
}

.card3-text2:active {
  background-color: rgb(207, 206, 206); /* 鼠标点击时的背景色 */
}

.img-EtP-7 {
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.card3-text3 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  text-align: left;
}

.card3-text4 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(230, 61, 57, 1);
}

.card3-text5 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}

.card3-text6 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(230, 61, 57, 1);
}

.card3-text7 {
  font-size: 13px;
  font-weight: 500;
  color: rgba(188, 193, 204, 1);
}

.card3-text8 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}

.vec-rectangle-vGs-4 {
  opacity: 0.5;
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 80px;
  width: auto;
  height: 300px;
  mix-blend-mode: normal;
  filter: blur(3px) brightness(0.9);
  overflow: hidden;
  object-fit: cover;
}

.drawer-div {
  transition: background-color 0.3s; /* 变色过渡效果 */
}

.drawer-div:active {
  background-color: rgb(207, 206, 206); /* 鼠标点击时的背景色 */
}
</style>
