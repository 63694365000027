import Vue from 'vue'
import VueRouter from 'vue-router'
//通道页面
import LoginH5 from '@/views/Login/newLogin.vue'
import ProductList from '@/views/product/newProduct.vue'
import UserSignRecord from "@/views/sign/userSignRecord.vue";
import ServiceAgreement from '@/views/Login/serviceAgreement.vue'
import BasNotice from '@/views/Login/notice.vue'
import BasLoginDisplay from '@/views/display/basLoginDisplay.vue'
import BasAuthen from '@/views/authen/index.vue'
import BasBank from '@/views/bank/index.vue'
import BasAddBank from '@/views/bank/add.vue'
import BasOrder from '@/views/order/index.vue'
import BasStep1 from '@/views/buy/step1.vue'
import BasStep2 from '@/views/buy/step2.vue'
import BasStep3 from '@/views/buy/step3.vue'
import BasStatement from '@/views/statement/index.vue'
import BasCompanyStatement from '@/views/statement/company.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/login/:type?/:deptId?/:productId?',
    name: 'login',
    component: LoginH5
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/product/:data?',
    name: 'product',
    component: ProductList
  },
  {
    path: '/productInfo/:data?',
    name: 'productInfo',
    component: () => import('@/views/product/newProductInfo.vue')
  },
  {
    path: '/userSignRecord/:data',
    name: 'userSignRecord',
    component: UserSignRecord
  },
  {
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    component: ServiceAgreement
  },
  {
    path: '/notice',
    name: 'notice',
    component: BasNotice
  },
  {
    path: '/basLoginDisplay/:type?/:deptId?/:productId?',
    name: 'basLoginDisplay',
    component: BasLoginDisplay
  },
  {
    path: '/authen',
    name: 'authen',
    component: BasAuthen
  },
  {
    path: '/bank',
    name: 'bank',
    component: BasBank,
    props: true
  },
  {
    path: '/addBank',
    name: 'addBank',
    component: BasAddBank,
    props: true
  },
  {
    path: '/order',
    name: 'order',
    component: BasOrder
  },
  {
    path: '/step1',
    name: 'step1',
    component: BasStep1,
    props: true
  },
  {
    path: '/step2',
    name: 'step2',
    component: BasStep2,
    props: true
  },
  {
    path: '/step3',
    name: 'step3',
    component: BasStep3
  },
  {
    path: '/statement',
    name: 'statement',
    component: BasStatement,
    props: true
  },
  {
    path: '/companyStatement',
    name: 'companyStatement',
    component: BasCompanyStatement,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   // 检查用户是否已经登录
//   const isAuthenticated = store.state.isAuthenticated
//   //判断是否是扫码登录
//   const isScan = store.state.sacnInfo.productId == '' ? false : true
//   if (!isAuthenticated && to.path !== '/login') {
//     // 如果用户未登录并尝试访问非登录页面，则重定向到登录页面
//     next('/login')
//   } else if (isAuthenticated && to.path === '/login') {
//     // 如果用户已登录且访问登录页，重定向到仪表板
//     next('/product')
//   } else {
//     // 其他情况放行
//     next()
//   }
// })

export default router



