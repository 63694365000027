import request from "@/utils/request";

// 获取用户绑定银行卡列表
export function getBankList(userId) {
  return request({
    url: '/api/news-app-user-api/api/bankcard/list/' + userId,
    method: 'get',
  })
}

// 添加银行卡
export function addBank(data) {
  return request({
    url: '/api/news-app-user-api/api/bankcard/save',
    method: 'post',
    data: data
  })
}
