<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">银行卡</span>
    </header>

    <div class="body">
      <div v-for="(item, index) in bankList" :key="index" @click="handleClick(item)">
        <div class="bank-card">
          <div class="bank-card-text1">{{item.bankName}}</div>
          <div class="bank-card-text2" v-if="item.bankCardType == 'DC'">储蓄卡</div>
          <div class="bank-card-text2" v-if="item.bankCardType == 'CC'">信用卡</div>
          <div class="bank-card-text3">{{handleBankNum(item.bankCard)}}</div>
        </div>
      </div>
      <div class="add-bank" @click="handleAdd">
        <div class="bank-text1">+</div>
        <div class="bank-text2">添加银行卡</div>
      </div>
    </div>
  </div>
</template>
<script>
import {getBankList} from '@/api/bank/bank'
export default {
  name: 'BankAuthen',
  props: {
    formData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      userId: this.$store.state.userId,
      bankList: [],
      localFormData: null
    };
  },
  created() {
    this.getList()
  },
  methods: {
    handleBack() {
      if (this.formData) {
        if (this.formData) {
          this.$router.push({ name: "step1", params: {formData: {
                money: this.formData.money,
                statementType: this.formData.statementType,
                companyType: this.formData.companyType,
                bankInfo: this.formData.bankInfo
              }
            }})
        }
      } else {
        this.$router.go(-1);
      }
    },
    getList() {
      getBankList(this.userId).then(res => {
        if (res.code == 200) {
          this.bankList = res.dataInfo
        }
      })
    },
    handleAdd() {
      this.$router.push('/addBank')
    },
    handleBankNum(value) {
      let num = value.toString()
      let sub = num.substring(num.length - 4, num.length)
      return '**** **** **** ' + sub
    },
    handleClick(item) {
      if (this.formData) {
        this.$router.push({ name: "step1", params: {formData: {
              money: this.formData.money,
              statementType: this.formData.statementType,
              companyType: this.formData.companyType,
              bankInfo: {
                bankName: item.bankName,
                bankCard: item.bankCard,
                bankAddress: item.bankAddress
              }
            }
          }})
      }
    }
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}

.bank-card {
  padding: 10px;
  height: 130px;
  border-radius: 10px;
  background: linear-gradient(135deg, rgba(60, 93, 170, 1) 0%, rgba(81, 131, 192, 1) 100%);
  margin-bottom: 10px;
}

.bank-card-text1 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}

.bank-card-text2 {
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
}

.bank-card-text3 {
  font-size: 30px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  margin-top: 30px;
}

.add-bank {
  display: flex;
  height: 60px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  transition: background-color 0.3s;
}

.add-bank:active {
  background-color: rgb(207, 206, 206); /* 鼠标点击时的背景色 */
}

.bank-text1 {
  font-size: 40px;
  margin-top: 10px;
}

.bank-text2 {
  font-size: 18px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  margin-top: 20px;
  margin-left: 10px;
}
</style>
