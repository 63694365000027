<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">购买清单</span>
    </header>
    <div class="body-head">
      <el-row>
        <div class="body-head-text1">持有资产（元）</div>
      </el-row>
      <div class="body-head-text2">
        {{userCount}}
      </div>
    </div>

    <div class="body">
      <el-tabs :stretch="true" class="centered-tabs" v-model="activeName" @tab-click="handleTableClick">
        <el-tab-pane label="全部" name="10">
          <div class="body-tabs-card" v-for="(item, index) in  userSignList" :key="index">
            <el-row>
              <el-col :span="12">
                <div class="card-text1">{{item.productNumber}}</div>
              </el-col>
              <el-col :span="12">
                <div class="card-text2" v-if="item.type == 0">待转账</div>
                <div class="card-text2" v-if="item.type == 1">已转账</div>
              </el-col>
            </el-row>
            <div class="img-EtP-7"></div>

            <el-row>
              <el-col :span="6">
                <div class="card-text3">产品名称:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text4">{{item.productName}}</div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px;">
              <el-col :span="6">
                <div class="card-text3">预约金额:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text5">{{item.money}}元</div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px;">
              <el-col :span="6">
                <div class="card-text3">预约时间:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text4">{{item.createTime}}</div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待转帐" name="0">
          <div class="body-tabs-card" v-for="(item, index) in  userSignList" :key="index">
            <el-row>
              <el-col :span="12">
                <div class="card-text1">{{item.productNumber}}</div>
              </el-col>
              <el-col :span="12">
                <div class="card-text2" v-if="item.type == 0">待转账</div>
              </el-col>
            </el-row>
            <div class="img-EtP-7"></div>

            <el-row>
              <el-col :span="6">
                <div class="card-text3">产品名称:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text4">{{item.productName}}</div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px;">
              <el-col :span="6">
                <div class="card-text3">预约金额:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text5">{{item.money}}元</div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px;">
              <el-col :span="6">
                <div class="card-text3">预约时间:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text4">{{item.createTime}}</div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已转账" name="1">
          <div class="body-tabs-card" v-for="(item, index) in  userSignList" :key="index">
            <el-row>
              <el-col :span="12">
                <div class="card-text1">{{item.productNumber}}</div>
              </el-col>
              <el-col :span="12">
                <div class="card-text2" v-if="item.type == 1">已转账</div>
              </el-col>
            </el-row>
            <div class="img-EtP-7"></div>

            <el-row>
              <el-col :span="6">
                <div class="card-text3">产品名称:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text4">{{item.productName}}</div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px;">
              <el-col :span="6">
                <div class="card-text3">预约金额:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text5">{{item.money}}元</div>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px;">
              <el-col :span="6">
                <div class="card-text3">预约时间:</div>
              </el-col>
              <el-col :span="18">
                <div class="card-text4">{{item.createTime}}</div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import {getUserTotalAsset} from '@/api/usersign/usersign'
import {list} from "@/api/usersign/usersign";
export default {
  name: 'OrderIndex',
  data() {
    return {
      activeName: '10',
      userId: this.$store.state.userId,
      // userId: '1260606752991215616',
      userCount: null,
      userSignList: [],
    }
  },
  created() {
    this.getUserCount()
    this.getUserSignList(null)
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    getUserCount() {
      getUserTotalAsset({userId: this.userId}).then(res => {
        if (res.code == 200) {
          this.userCount = res.dataInfo
        }
      })
    },
    handleTableClick(tab, event) {
      if (tab.name == '10') {
        this.getUserSignList()
      } else {
        this.getUserSignList(tab.name)
      }
    },
    getUserSignList(item) {
      const params = {
        userId: this.userId,
        type: item
      }
      list(params).then(res => {
        this.userSignList = res.dataInfo;
      })
    }
  }
}

</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background: linear-gradient(90deg, rgba(247, 219, 186, 1) 0%, rgba(236, 189, 138, 1) 100%);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.body-head {
  height: 200px;
  background: linear-gradient(90deg, rgba(247, 219, 186, 1) 0%, rgba(236, 189, 138, 1) 100%);
}

.body-head-text1 {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  //text-align: center;
  margin-top: 30px;
}

.body-head-text2 {
  font-size: 36px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin-top: 50px;
}

.body {
  padding: 10px;
}

.centered-tabs {
  //display: flex;
  //justify-content: center;
}

::v-deep .is-active {
  color: rgba(236, 189, 138, 1) !important;
}

::v-deep .el-tabs__active-bar {
  background-color: rgba(236, 189, 138, 1) !important;
}

.body-tabs-card {
  height: 150px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  margin-bottom: 10px;
}

.card-body {
  display: flex;
}

.card-text1 {
  text-align: left;
}

.card-text2 {
  text-align: right;
}

.img-EtP-7 {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.card-text3 {
  text-align: left;

  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.card-text4 {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
}

.card-text5 {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: rgba(212, 48, 48, 1);
}
</style>
