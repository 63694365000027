<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">产品列表</span>
    </header>

    <div class="body">
      <img class="titleImg" v-if="imgUrl" :src="imgUrl">
      <div class="card" v-for="(item, index) in dataList" :key="index">
        <div style="display: flex">
          <div class="card-title">{{item.cpmc}}</div>
          <div class="card-view" @click="toInfo(item)">
            <div style="font-size: 10px; color: rgba(255, 255, 255, 1); margin-top: 2px;">查看详情</div>
          </div>
        </div>
        <el-row style="margin-top: 10px;">
          <el-col :span="8">
            <div class="card-text1">
              <div style="color: rgba(230, 61, 57, 1);font-size: 24px;">{{item.yjbcjtjz}}</div>
              <div style="color: rgba(230, 61, 57, 1);font-size: 14px;margin-top: 10px;">%</div>
            </div>
            <div class="card-text2">
              计提基准
            </div>
          </el-col>
          <el-col :span="8">
            <div class="card-text1">
              <div style="color: rgba(56, 56, 56, 1);font-size: 24px;">{{item.tzqx}}</div>
              <div style="color: rgba(56, 56, 56, 1);font-size: 14px;margin-top: 5px;">天</div>
            </div>
            <div class="card-text2">
              投资期限
            </div>
          </el-col>
          <el-col :span="8">
            <div class="card-text1">
              <div style="color: rgba(56, 56, 56, 1);font-size: 24px;">{{item.qtxe / 10000}}</div>
              <div style="color: rgba(56, 56, 56, 1);font-size: 14px;margin-top: 5px;">万</div>
            </div>
            <div class="card-text2">
              起投金额
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import {decryptByAES, encryptByAES} from "@/utils/secretKey";
import {list} from "@/api/product/product";
import {indexImg} from '@/api/set/basSet'

export default {
  name: 'productInfo',
  data() {
    return {
      name: '',
      colorArray: ['#e2cfa5', '#EF9942', '#3F87ED', '#E44A4A'],
      dataList: [],
      loading: true,
      deptId: this.$store.state.deptId,
      imgUrl: null
    }
  },
  created() {
    console.log(this.$store.state)
    this.getIndexImg()
  },
  mounted() {
    this.getList()
  },
  methods: {
    getIndexImg() {
      indexImg(this.deptId).then(res => {
        if (res.code == 200 && res.dataInfo != null) {
          this.imgUrl = res.dataInfo
        }
      })
    },
    handleBack() {
      this.$router.go(-1)
    },
    toInfo(data) {
      this.$store.commit('setProductId', data.id)
      this.$router.push('/productInfo')
    },
    getList() {
      this.loading = true
      const params = {
        deptId: this.$store.state.deptId
      }
      list(params).then(res => {
          if (res != null & res.code == 200) {
            this.dataList = res.dataInfo
            if (this.dataList.length > 0) {
              let i = 0
              for (let a = 0; a < this.dataList.length; a++) {
                if (i >= this.colorArray.length) {
                  i = 0
                }
                this.dataList[a].color = (this.colorArray[i])
                i++
              }
            }
          }
          setTimeout(() => {
            this.loading = false;
          }, 20);
        }
      )
    },
  },
}

</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 20px;
  margin-bottom: 80px;
}

.titleImg {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.card {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(153, 153, 153, 0.5);
  margin-top: 20px;
  padding: 10px;
}

.card-title {
  text-align: left;
  font-size: 14px;
  font-weight: bolder;
}

.card-view {
  position: absolute;
  right: 40px;
  border-radius: 79px;
  background: rgba(234, 182, 125, 1);
  width: 66px;
  height: 19px;
}

.card-text1 {
  display: flex;
  margin-left: 40px;
}

.card-text2 {
  font-size: 13px;
  font-weight: 500;
  color: rgba(188, 193, 204, 1);
}
</style>
