<template>
  <div v-loading="loading">
    <div v-if="tableData.length > 0">
      <el-card v-for="(item,key) in tableData" :key="key" class="sign-card">
        <el-row style="text-align: left">
          <el-col class="sign-col">产品名称: {{ item.productName }}</el-col>
        </el-row>
        <el-row style="text-align: left">
          <el-col class="sign-col">购买金额: {{ item.money }} 元</el-col>
        </el-row>
        <el-row style="text-align: left">
          <el-col class="sign-col" v-if="item.type == 0">打款状态：未签约</el-col>
          <el-col class="sign-col" v-if="item.type == 1">打款状态：已签约</el-col>
          <el-col class="sign-col" v-if="item.type == 2">打款状态：已超时</el-col>
        </el-row>
        <el-row style="text-align: left" v-if="item.type == 1">
          <el-col class="sign-col"><a :href="item.longUrl">点击查看合同</a></el-col>
        </el-row>
      </el-card>
    </div>
    <el-card v-if="tableData.length == 0" class="sign-card">
      <span style="color: white">暂无数据</span>
    </el-card>
  </div>
</template>
<script>
import {list} from "@/api/usersign/usersign";

export default {
  data() {
    return {
      loading: false,
      tableData: [],
    }
  },
  methods: {
    getTable(item) {
      this.loading = true
      const params = {
        userId: this.$store.state.userInfo.id,
        type: item
      }
      list(params).then(res => {
        this.tableData = res.rows;
        setInterval(() => {
          this.loading = false
        }, 300)
      })
    },
  },
  created() {
  },


}
</script>
<style scoped lang="scss">
.sign-card {
  border:1px #D9BB85 solid;
  border-radius: 10px;
  margin-top: 1em;
  //background-image: url("~@/assets/product-ground.png");
  background-color: #ff6243;
  background-size: cover;
}
.sign-col {
  font-size: 14px;
  color: white;
  font-weight: bold;
}
</style>
