<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      view: true,
      home: 'homed1',
      userCenter: 'userCenter1',
    }
  },
  mounted() {
  },
  methods: {
    lockDesktopSize() {
      // 设置默认的桌面大小
      const desktopWidth = 1920;
      const desktopHeight = 1080;

      // 监听窗口大小变化
      window.addEventListener('resize', () => {
        // 设置最大宽度和高度为桌面尺寸
        document.body.style.maxWidth = `${desktopWidth}px`;
        document.body.style.maxHeight = `${desktopHeight}px`;

        // 根据窗口大小调整body的宽度和高度
        document.body.style.width = `${desktopWidth}px`;
        document.body.style.height = `${desktopHeight}px`;
      });

      // 触发一次resize事件来初始化设置
      window.dispatchEvent(new Event('resize'));
    },
  }
}
</script>
<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
