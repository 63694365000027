<template>
  <div class="app">
    <header class="header">
      <div class="back" @click="handleBack">
        <i class="el-icon-arrow-left" style="left: 0"></i>
      </div>
      <span class="title">产品购买</span>
    </header>

    <div class="body">
      <div class="body-head">{{ data.cpmc }}</div>
      <div class="body-card1">
        <div class="card1-text1">买入金额</div>
        <el-row style="margin-top: 10px;">
          <el-col :span="2">
            <div class="card1-text2">￥</div>
          </el-col>
          <el-col :span="22">
            <el-form :model="form" :rules="payPageRules" ref="payPageForm">
              <el-form-item prop="money">
                <el-input :placeholder="inputText" v-model="form.money"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <div class="img-EtP-71"></div>
        <div class="card1-text3">起购金额
          <span style="color: rgba(234, 182, 125, 1);">{{ data.qtxe / 10000 }}万元</span>
          ，追加最低金额1元
          <span style="color: rgba(234, 182, 125, 1);">（不含手续费）</span>
        </div>
      </div>

      <div class="body-card2">
        <el-row>
          <el-col :span="12">
            <div class="card1-text1">付款方式</div>
          </el-col>
          <el-col :span="12">
            <div class="card2-text1" @click="handleAddBank">添加银行卡</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>

        <el-row>
          <el-col :span="12">
            <div class="card2-text2">银行名称</div>
          </el-col>
          <el-col :span="12">
            <div class="card2-text3" @click="handleChooseBank">{{ bankInfo.bankName }}<i
              class="el-icon-caret-right"></i></div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="12">
            <div class="card2-text2">银行卡号</div>
          </el-col>
          <el-col :span="12">
            <div class="card2-text3">{{ bankInfo.bankCard }}</div>
          </el-col>
        </el-row>
        <div class="img-EtP-7"></div>
        <el-row>
          <el-col :span="12">
            <div class="card2-text2">开户行</div>
          </el-col>
          <el-col :span="12">
            <div class="card2-text3">{{ bankInfo.bankAddress }}</div>
          </el-col>
        </el-row>
      </div>

      <div class="checkbox-group">
        <el-checkbox class="body-checkbox" v-model="fxjzs" @change="handleStatement">
          我已阅读并同意<span class="span-text1">《风险揭示书》</span>
        </el-checkbox>
        <el-checkbox class="body-checkbox" v-model="mzxy">
          我已阅读并同意<span class="span-text1" @click="handleCompanyStatement">《公司免责协议》</span>
        </el-checkbox>
      </div>

      <el-button class="but-next" @click="handleNext">
        <div class="but-text">下一步</div>
      </el-button>

    </div>
  </div>
</template>
<script>
import {getProductInfo} from '@/api/product/product';
import {getBankList} from '@/api/bank/bank'
import {getMyIdCardAuthenInfoV2} from "@/api/authen/authen";

export default {
  name: 'BuyStep1',
  props: {
    formData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      userId: this.$store.state.userId,
      inputText: '',
      fxjzs: false,
      mzxy: false,
      productId: this.$store.state.productId,
      data: {},
      form: {
        money: null,
      },
      bankInfoList: [],
      bankInfo: {},
      payPageRules: {
        money: [
          {required: true, message: '请输入购买金额', trigger: 'blur'},
          {validator: this.validateAmount, trigger: 'blur'}
        ]
      },
      userInfo: {}
    }
  },
  created() {
    this.getProductView()
    this.getBankListInfo()
    if (this.formData) {
      if (this.formData.money) {
        this.form.money = this.formData.money
      }
      if (this.formData.statementType) {
        this.fxjzs = true
      }
      if (this.formData.companyType) {
        this.mzxy = true
      }
    }
  },
  methods: {
    handleBack() {
      this.$router.go(-2)
    },
    handleNext() {
      this.$refs.payPageForm.validate((valid) => {
        if (valid) {
          if (this.fxjzs && this.mzxy) {
            getMyIdCardAuthenInfoV2({userid: this.userId, authentype: 0}).then(res => {
              if (res.code == 200) {
                if (res.dataInfo != null) {
                  this.userInfo = res.dataInfo
                  this.$store.commit('setIdCard', res.dataInfo.usernum)
                  this.$store.commit('setPayDataInfo', {
                    deptId: this.$store.state.deptId,
                    productId: this.productId,
                    userId: this.$store.state.userId,
                    userName: this.userInfo.username,
                    phone: this.$store.state.phone,
                    idCard: this.userInfo.usernum,
                    bankCard: this.bankInfo.bankCard,
                    bankName: this.bankInfo.bankName,
                    bankAddress: this.bankInfo.bankAddress,
                    money: this.form.money,
                    type: 1
                  })
                  this.$router.push("/step2")
                }
              }
            })
          } else {
            this.$message.error('请勾选协议')
          }
        }
      })
    },
    getProductView() {
      getProductInfo(this.productId).then(res => {
        if (res.code == 200 && res.dataInfo != null) {
          this.data = res.dataInfo
          this.inputText = this.data.qtxe + '元起购'
        }
      })
    },
    handleAddBank() {
      this.$router.push({
        name: 'addBank', params: {
          formData: {
            money: this.form.money,
            statementType: this.fxjzs,
            companyType: this.mzxy,
            bankInfo: this.bankInfo
          }
        }
      });
    },
    getBankListInfo() {
      getBankList(this.userId).then(res => {
        if (res.code == 200) {
          this.bankInfoList = res.dataInfo
          if (this.formData && this.formData.bankInfo) {
            this.bankInfo = this.formData.bankInfo
          } else {
            this.bankInfo = this.bankInfoList[0]
          }
        }
      })
    },
    handleChooseBank() {
      this.$router.push({
        name: 'bank', params: {
          formData: {
            money: this.form.money,
            statementType: this.fxjzs,
            companyType: this.mzxy,
            bankInfo: this.bankInfo
          }
        }
      });
    },
    handleStatement() {
      this.$router.push({
        name: "statement", params: {
          formData: {
            productName: this.data.cpmc,
            money: this.form.money,
            statementType: this.fxjzs,
            companyType: this.mzxy,
            bankInfo: this.bankInfo
          }
        }
      })
    },
    handleCompanyStatement() {
      this.$router.push({
        name: "companyStatement", params: {
          formData: {
            productName: this.data.cpmc,
            money: this.form.money,
            statementType: this.fxjzs,
            companyType: this.mzxy,
            bankInfo: this.bankInfo
          }
        }
      })
    },
    validateAmount(rule, value, callback) {
      if (!value) {
        return callback(new Error('购买金额不能为空'));
      }
      // 检查是否为有效数字
      if (!/^\d+(\.\d+)?$/.test(value)) {
        callback(new Error('请输入有效的数字'));
      } else {
        // 检查金额是否低于最小值
        if (parseFloat(value) < this.data.qtxe) {
          callback(new Error('购买金额不能小于' + this.data.qtxe + '元'));
        } else {
          callback();
        }
      }
    },
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.back {
  position: absolute;
  left: 10px;
  cursor: pointer;
  color: black;
  font-size: 25px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.body {
  padding: 10px;
}

.body-head {
  font-size: 14px;
  font-weight: 500;
  color: rgba(208, 208, 208, 1);
  text-align: left;
}

.body-card1 {
  height: 120px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  margin-top: 10px;
  padding: 10px;
}

.card1-text1 {
  font-size: 18px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card1-text2 {
  font-size: 20px;
  color: rgba(56, 56, 56, 1);
  text-align: left;
  margin-top: 5px;
}

.img-EtP-7 {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.img-EtP-71 {
  //margin-top: 10px;
  //margin-bottom: 10px;
  border: 1px solid rgba(229, 229, 229, 0.4);
}

.card1-text3 {
  font-size: 12px;
  font-weight: 500;
  color: rgba(166, 166, 166, 1);
  text-align: left;
}

.body-card2 {
  height: 160px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  margin-top: 10px;
  padding: 10px;
}

.card2-text1 {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  color: rgba(166, 166, 166, 1);
}

.card2-text2 {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.card2-text3 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  text-align: right;
}

.checkbox-group {
  text-align: left;
}

::v-deep .el-checkbox__inner {
  border-radius: 20px;
}

::v-deep .el-input__inner {
  border: 0px solid #DCDFE6;
}

.body-checkbox {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}

.span-text1 {
  font-size: 14px;
  font-weight: 500;
  color: rgba(250, 0, 0, 1);
}

.but-next {
  margin-top: 20px;
  width: 300px;
  height: 50px;
  border-radius: 37px;
  background: linear-gradient(135deg, rgba(247, 219, 186, 1) 0%, rgba(234, 182, 125, 1) 100%);
}

.but-text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
</style>
