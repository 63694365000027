import request from '@/utils/request'

// 登录
export async function loginByCode (data) {
  return await request({
    url: '/api/user-api/api/loginByPhoneCode',
    method: 'post',
    data: data
  })
}

// 发送验证码
export async function sendMessageCode (data) {
  return await request({
    url: '/api/user-api/api/sendLoginNoByPhone',
    method: 'post',
    data: data
  })
}



