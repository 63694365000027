<template>
  <div>
    <header class="header">
      <span class="title">产品列表</span>
    </header>

    <el-card class="box-card">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="total">
          <UserSignTable ref="total"></UserSignTable>
        </el-tab-pane>
        <el-tab-pane label="已签约" name="isPay">
          <UserSignTable ref="isPay"></UserSignTable>
        </el-tab-pane>
        <el-tab-pane label="未签约" name="waitingPay">
          <UserSignTable ref="waitingPay"></UserSignTable>
        </el-tab-pane>
        <el-tab-pane label="已超时" name="isOver">
          <UserSignTable ref="isOver"></UserSignTable>
        </el-tab-pane>
      </el-tabs>
    </el-card>

    <footer class="footer">
      <button class="home-btn" @click="handleRouter(0)">
        <svg-icon :icon-class="home"/>
        <div style="font-size: 10px; color: #C7C7C7">首页</div>
      </button>
      <button class="profile-btn" @click="handleRouter(1)">
        <svg-icon :icon-class="userCenter"/>
        <div style="font-size: 10px; color: #C7C7C7">个人中心</div>
      </button>
    </footer>
  </div>
</template>

<script>
import UserSignTable from "@/views/sign/userSignTable.vue";
import {encryptByAES, decryptByAES } from "@/utils/secretKey";

export default {
  name: 'userSignRecord',
  components: {UserSignTable},
  data() {
    return {
      home: 'home1',
      userCenter: 'userCenterd2',
      type: null,
      activeName: 'total',
      tableData: []
    }
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name === 'total') {
        this.$refs.total.getTable(null);
      } else if (tab.name === 'isPay') {
        this.$refs.isPay.getTable(1);
      } else if (tab.name === 'waitingPay') {
        this.$refs.waitingPay.getTable(0);
      } else if (tab.name === 'isOver') {
        this.$refs.isOver.getTable(2);
      }
    },
    handleBack() {
      this.$router.go(-1)
    },
    handleRouter(type) {
      if(type == 0) {
        this.home = 'home1'
        this.userCenter = 'userCenterd2'
        let params = {
          userId: this.$store.state.sacnInfo.userId,
          deptId: this.$store.state.sacnInfo.deptId,
          productId: this.$store.state.sacnInfo.productId,
        }
        this.$router.push('/product/' + encryptByAES(params))
      }
    }
  },
  created() {
    // 解密
    let params = decryptByAES(this.$route.params.data)
    if (params != null) {
      this.$store.state.sacnInfo.userId = params.userId
      this.$store.state.sacnInfo.deptId = params.deptId
      this.$store.state.sacnInfo.productId = params.productId
    }
  },
  mounted() {
    this.$refs.total.getTable(null);
  }
}
</script>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center; /* 使标题居中显示 */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  line-height: 40px;
}

.totalDesc {
  text-align: left;
}

.sign-details {
  margin-top: 1em;
}

.box-card {
  width: 100%;
  max-height:100%;
  background-color: white;
  margin-bottom: 60px;
}

::v-deep .el-card__header {
  border-bottom: 1px solid #373737;
}

::v-deep .is-active {
  color: #ff6243 !important;
}

::v-deep .el-tabs__active-bar {
  background-color: #ff6243 !important;
}

::v-deep .el-tabs__item {
  color: black;
}

</style>
